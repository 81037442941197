import React  from 'react';
import {Grid} from '@mui/material';
import {Box} from '@mui/material';
import TextField from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';


const SearchPriceui=(props:any)=>{
    const {enterMinimumvalue,enterMaxvalue,minValue,maxValue} = props;

    return(<>
    <Box>

        <Grid  >

            <Grid xs={12} sm={12} md={12} lg={12}  xl={12} >

            <TextField  name="pricemin" 



  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        ₹
      </InputAdornment>
    ),
  }} label="Minimum" onChange={enterMinimumvalue}  value={minValue} type="number" variant="filled" id="pricemin"   sx={{ marginRight: '1px'}}  >
</TextField>
<TextField  name="pricemax"  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        ₹
      </InputAdornment>
    ),
  }} label="Maximum" type="number" variant="filled" id="pricemax" onChange={enterMaxvalue} value={maxValue} sx={{ marginLeft: '1px'}}   >
</TextField>
            </Grid>


        </Grid>
    </Box>
    </>)
}


export default SearchPriceui;