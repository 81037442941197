import React, { useState } from "react";
import "./Coupon.css";
import Axios from "axios";

const Coupon = () => {
  
  const [formData, setFormData] = useState({
    coupon_code: "",
    coupon_type: "",
    short_description: "",
    long_description: "",
    percentage_value: "",
    flat_amount: "",
    start_date: "",
    end_date: "",
    coupon_counts: "",
    reuse_days: "",
    applied_counts: "",
    product_id: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    //e.preventDefault();

    Axios.post("http://localhost:3001/submit-coupon", formData)
      .then((response: any) => {
        console.log(response.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (
    <div className="formbold-main-wrapper">
      <div className="formbold-form-wrapper">
        <h2 className="formbold-form-title">Coupon Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">Coupon Code:</label>
            <input
              type="text"
              name="coupon_code"
              value={formData.coupon_code}
              onChange={handleInputChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">Coupon Type:</label>
            <select
              name="coupon_type"
              value={formData.coupon_type}
              onChange={handleSelectChange}
              className="formbold-form-input"
            >
              <option value="A">Type A</option>
              <option value="B">Type B</option>
              <option value="C">Type C</option>
            </select>
          </div>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">Short Description:</label>
            <input
              type="text"
              name="short_description"
              value={formData.short_description}
              onChange={handleInputChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">Long Description:</label>
            <textarea
              name="long_description"
              value={formData.long_description}
              onChange={handleTextareaChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">Percentage Value:</label>
            <input
              type="text"
              name="percentage_value"
              value={formData.percentage_value}
              onChange={handleInputChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">Flat Amount:</label>
            <input
              type="text"
              name="flat_amount"
              value={formData.flat_amount}
              onChange={handleInputChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">Start Date:</label>
            <input
              type="date"
              name="start_date"
              value={formData.start_date}
              onChange={handleInputChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">End Date:</label>
            <input
              type="date"
              name="end_date"
              value={formData.end_date}
              onChange={handleInputChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">Coupon Counts:</label>
            <input
              type="number"
              name="coupon_counts"
              value={formData.coupon_counts}
              onChange={handleInputChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">Reuse Days:</label>
            <input
              type="number"
              name="reuse_days"
              value={formData.reuse_days}
              onChange={handleInputChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">Applied Counts:</label>
            <input
              type="number"
              name="applied_counts"
              value={formData.applied_counts}
              onChange={handleInputChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label className="formbold-form-label">Product ID:</label>
            <input
              type="number"
              name="product_id"
              value={formData.product_id}
              onChange={handleInputChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <button type="submit" className="formbold-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Coupon;
