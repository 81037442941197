import React from 'react'
import { ProductCampBreadcrumb } from '../../components/layouts/Breadcrumb'

const ProductCampaign = () => {
  return (
    <><ProductCampBreadcrumb />
    <div>ProductCampaign</div></>
  )
}

export default ProductCampaign