import * as React from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import OrderItems from "./newOrderComponents/OrderItems";




interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            label="Open 2 Prepare"
            {...a11yProps(0)}
            sx={{ background: "white", color: "black" }}
          />
          <Tab
            label="Prepare 2 Prepared"
            {...a11yProps(1)}
            sx={{ background: "white", color: "black" }}
          />
          <Tab
            label="Prepare 2 Out"
            {...a11yProps(2)}
            sx={{ background: "white", color: "black" }}
          />
          <Tab
            label="Out 2 Delivered"
            {...a11yProps(3)}
            sx={{ background: "white", color: "black" }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <Grid
          container
          rowSpacing={{ xs: 2, sm: 2, md: 3 }}
          columnSpacing={{ xs: 0, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" justifyContent="flex-start">
              Active - Prepare Orders
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
          <Grid item md={2} lg={2} xl={2}></Grid>
          <Grid item md={1} lg={2} xl={2}></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <OrderItems type={"active"} />
          </Grid>
          <Grid item md={1} lg={2} xl={2}></Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Grid
          container
          rowSpacing={{ xs: 2, sm: 2, md: 3 }}
          columnSpacing={{ xs: 0, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" justifyContent="flex-start">
              Preparing- Prepared Orders
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
          <Grid item md={2} lg={2} xl={2}></Grid>
          <Grid item md={1} lg={2} xl={2}></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <OrderItems type={"p2p"} />
          </Grid>
          <Grid item md={1} lg={2} xl={2}></Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <Grid
          container
          rowSpacing={{ xs: 2, sm: 2, md: 3 }}
          columnSpacing={{ xs: 0, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" justifyContent="flex-start">
              Prepared - Out Orders
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
          <Grid item md={2} lg={2} xl={2}></Grid>
          <Grid item md={1} lg={2} xl={2}></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <OrderItems type={"p2d"} />
          </Grid>
          <Grid item md={1} lg={2} xl={2}></Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <Grid
          container
          rowSpacing={{ xs: 2, sm: 2, md: 3 }}
          columnSpacing={{ xs: 0, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" justifyContent="flex-start">
              Out - Delivered Orders
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
          <Grid item md={2} lg={2} xl={2}></Grid>
          <Grid item md={1} lg={2} xl={2}></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <OrderItems type={"delivered"} />
          </Grid>
          <Grid item md={1} lg={2} xl={2}></Grid>
        </Grid>
      </TabPanel>
    </Box>
  );
}
