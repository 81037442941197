import React, { useEffect, useState } from "react";
import http from "../../http-common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  AppBar,
  Toolbar,
  Button,
} from "@mui/material";

const Orders = () => {
  const [orders, setOrders] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get("/orders");
        const data = response.data;
        setOrders(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  const handleDateOption = (option: string) => {
    let startDate = null;
    let endDate = null;
    const today = new Date();
    switch (option) {
      case "yesterday":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 1);
        endDate = new Date(today);
        endDate.setDate(today.getDate() - 1);
        break;
      case "today":
        startDate = new Date(today);
        endDate = new Date(today);
        break;
      case "sixMonthsAgo":
        startDate = new Date(today);
        startDate.setMonth(today.getMonth() - 6);
        endDate = new Date(today);
        break;
      default:
        break;
    }
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredOrders = orders.filter((order: any) => {
    const orderDate = new Date(order.orderDate);
    if (startDate && endDate) {
      return orderDate >= startDate && orderDate <= endDate;
    }
    return true;
  });

  return (
    <>
    <AppBar position="static">
    <Toolbar sx={{height:"10px", background:"white"}}>
        <div style={{ marginLeft: "10px" , marginTop: "1px"}}>

        <Button 
          variant="contained"  
          sx={{ background: "black", color: "white" }}
          onClick={() => handleDateOption("yesterday")}>
          Yesterday
        </Button>
        </div>

          <div style={{ marginLeft: "10px" , marginTop: "1px"}}>
          <Button 
            variant="contained" 
            sx={{ background: "black", color: "white" }}
            onClick={() => handleDateOption("today")}>
          Today
          </Button>
          </div>

          <div style={{ marginLeft: "10px" , marginTop: "1px"}}>
          <Button
            variant="contained"
            sx={{ background: "black", color: "white" }}
            onClick={() => handleDateOption("sixMonthsAgo")} >
            6 Months Ago
          </Button>
          </div>

          <Grid sx={{height: "70px", marginLeft: "40px" , marginTop: "20px" }}>
          <div className="datepicker-wrapper">
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Select From Date"     
            />
            </div>
            </Grid>

            <Grid sx={{height: "70px", marginLeft: "40px" , marginTop: "20px" }}>
            <div className="datepicker-wrapper">
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="Select To Date"
            />
            </div>
          </Grid>
          </Toolbar>
    </AppBar>
    <Grid container>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                {/* ... Table header cells ... */}
                <TableCell
                style={{ textAlign: "center" }}
                sx={{ fontWeight: "bold" }}
              >
                S.No
              </TableCell>
              <TableCell
                style={{ textAlign: "center" }}
                sx={{ fontWeight: "bold" }}
              >
                Product Name
              </TableCell>
              <TableCell
                style={{ textAlign: "center" }}
                sx={{ fontWeight: "bold" }}
              >
                Date & Time
              </TableCell>
              <TableCell
                style={{ textAlign: "center" }}
                sx={{ fontWeight: "bold" }}
              >
                Order Price
              </TableCell>
              <TableCell
                style={{ textAlign: "center" }}
                sx={{ fontWeight: "bold" }}
              >
                Payment Method
              </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders.map((order: any, index: any) => (
                <TableRow key={order._id}>
                    <TableCell style={{ textAlign: "center" }}>
                     {index + page * rowsPerPage + 1}
                    </TableCell>    

                  <TableCell style={{ textAlign: "center" }}>
                   {order.orderItems &&
                   order.orderItems[0].products &&
                   order.orderItems[0].products.length > 0
                     ? order.orderItems[0].products
                         .map((product: any) => product.productName)
                         .join(", ")
                     : order.orderItems.map((item:any) => item.productName).join(",")}
                 </TableCell>

                 <TableCell style={{ textAlign: "center" }}>
                   {new Date(order.orderDate).toLocaleString()}
                 </TableCell>

                 <TableCell style={{ textAlign: "center" }}>
                   {order.orderPrice}
                 </TableCell>
                 {/* <TableCell style={{ textAlign: "center"}}>{order.paymentDone ? "Paid" : "UnPaid"}</TableCell> */}
                 <TableCell style={{ textAlign: "center" }}>
                   {order.paymentMethod}
                 </TableCell>                   
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[15, 25, 50]}
            component="div"
            count={orders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Orders;

// import React, { useEffect, useState } from "react";
// import http from "../../http-common";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import {
//   Grid,
//   Table,
//   TableHead,
//   TableBody,
//   TableRow,
//   TableCell,
//   TablePagination,
//   AppBar,
//   Toolbar,
//   Button,
// } from "@mui/material";
// import Sidebar from "./Sidebar"; 

// const Orders = () => {
//   const [orders, setOrders] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(15);
//   const [startDate, setStartDate] = useState<Date | null>(null);
//   const [endDate, setEndDate] = useState<Date | null>(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await http.get("/orders");
//         const data = response.data;
//         setOrders(data);
//       } catch (error) {
//         console.error("Error:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleStartDateChange = (date:any) => {
//     setStartDate(date);
//   };

//   const handleEndDateChange = (date:any) => {
//     setEndDate(date);
//   };

//   const handleDateOption = (option:string) => {
//     let startDate = null;
//     let endDate = null;
//     const today = new Date();
//     switch (option) {
//       case "yesterday":
//         startDate = new Date(today);
//         startDate.setDate(today.getDate() - 1);
//         endDate = new Date(today);
//         endDate.setDate(today.getDate() - 1);
//         break;
//       case "today":
//         startDate = new Date(today);
//         endDate = new Date(today);
//         break;
//       case "sixMonthsAgo":
//         startDate = new Date(today);
//         startDate.setMonth(today.getMonth() - 6);
//         endDate = new Date(today);
//         break;
//       default:
//         break;
//     }
//     setStartDate(startDate);
//     setEndDate(endDate);
//   };

//   const handleChangePage = (event:any, newPage:any) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event:any) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };
//   const handleSidebarItemClick = (option : any) => {
//     // Handle sidebar item click logic here
//     console.log(`Clicked on ${option} in the sidebar`);
//   };
//   const filteredOrders = orders.filter((order: any) => {
//     const orderDate = new Date(order.orderDate);
//     if (startDate && endDate) {
//       return orderDate >= startDate && orderDate <= endDate;
//     }
//     return true;
//   });

//   return (
//     <>
//       <AppBar position="static">
//         <Toolbar sx={{ height: "10px", background: "white" }}>

//           <Grid
//             sx={{ height: "70px", marginLeft: "40px", marginTop: "20px" }}
//           >
//             <div className="datepicker-wrapper">
//               <DatePicker
//                 selected={startDate}
//                 onChange={handleStartDateChange}
//                 selectsStart
//                 startDate={startDate}
//                 endDate={endDate}
//                 placeholderText="Select From Date"
//               />
//             </div>
//           </Grid>

//           <Grid
//             sx={{ height: "70px", marginLeft: "40px", marginTop: "20px" }}
//           >
//             <div className="datepicker-wrapper">
//               <DatePicker
//                 selected={endDate}
//                 onChange={handleEndDateChange}
//                 selectsEnd
//                 startDate={startDate}
//                 endDate={endDate}
//                 minDate={startDate}
//                 placeholderText="Select To Date"
//               />
//             </div>
//           </Grid>
//         </Toolbar>
//       </AppBar>
//       <Grid container>
//         <Grid item xs={12}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 {/* ... Table header cells ... */}
//                 <TableCell
//                   style={{ textAlign: "center" }}
//                   sx={{ fontWeight: "bold" }}
//                 >
//                   S.No
//                 </TableCell>
//                 <TableCell
//                   style={{ textAlign: "center" }}
//                   sx={{ fontWeight: "bold" }}
//                 >
//                   Product Name
//                 </TableCell>
//                 <TableCell
//                   style={{ textAlign: "center" }}
//                   sx={{ fontWeight: "bold" }}
//                 >
//                   Date & Time
//                 </TableCell>
//                 <TableCell
//                   style={{ textAlign: "center" }}
//                   sx={{ fontWeight: "bold" }}
//                 >
//                   Order Price
//                 </TableCell>
//                 <TableCell
//                   style={{ textAlign: "center" }}
//                   sx={{ fontWeight: "bold" }}
//                 >
//                   Payment Method
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {filteredOrders.map((order:any, index:any) => (
//                 <TableRow key={order._id}>
//                   <TableCell style={{ textAlign: "center" }}>
//                     {index + page * rowsPerPage + 1}
//                   </TableCell>

//                   <TableCell style={{ textAlign: "center" }}>
//                     {order.orderItems &&
//                     order.orderItems[0].products &&
//                     order.orderItems[0].products.length > 0
//                       ? order.orderItems[0].products
//                           .map((product:any) => product.productName)
//                           .join(", ")
//                       : order.orderItems.map((item:any) => item.productName).join(",")}
//                   </TableCell>

//                   <TableCell style={{ textAlign: "center" }}>
//                     {new Date(order.orderDate).toLocaleString()}
//                   </TableCell>

//                   <TableCell style={{ textAlign: "center" }}>
//                     {order.orderPrice}
//                   </TableCell>

//                   <TableCell style={{ textAlign: "center" }}>
//                     {order.paymentMethod}
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>

//           {/* Pagination */}
//           <TablePagination
//             rowsPerPageOptions={[15, 25, 50]}
//             component="div"
//             count={orders.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default Orders;