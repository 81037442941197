import React from 'react'
import { PagepreviligeBreadcrumb } from '../../components/layouts/Breadcrumb'

const PagesPrevilage = () => {
  return (
    <><PagepreviligeBreadcrumb />
    <div>PagesPrevilage</div></>
  )
}

export default PagesPrevilage