import React from 'react'
import { UseractionsBreadcrumb } from '../../components/layouts/Breadcrumb'

const UserActionReport = () => {
  return (
   <><UseractionsBreadcrumb />
   <div>UserActionReport</div></>
  )
}

export default UserActionReport