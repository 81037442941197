import React from 'react'
import { CompleteOrderBreadcrumb } from '../../components/layouts/Breadcrumb'

const CompletedOrders = () => {
  return (
    <><CompleteOrderBreadcrumb />
    <div>CompletedOrders</div></>
  )
}

export default CompletedOrders