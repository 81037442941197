import React from 'react';
import {Box} from '@mui/material';
import {Grid} from '@mui/material';
import TextField from '@mui/material/TextField';


const SearchOrderIdUi=(props:any)=>{

    const {enterSearchterms,searchOrderId}=props;


    return(<>
    <Box>

<Grid  >
<Grid xs={12} sm={12} md={12} lg={12}  xl={12}  >
<TextField  name="searchvalue" label="Search Terms" onChange={enterSearchterms} value={searchOrderId||''} className='formbold-form-input' type="search" variant="filled" id="searchvalue"  >
</TextField>
</Grid>
</Grid>
</Box>
    </>)

}


export default SearchOrderIdUi;