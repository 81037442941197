import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box'



const SearchDateUi = (props:any) => {

const{selectedfromDate,selectedtoDate,handleChangeFromDate,handleChangeToDate}=props;

  return (


    <Box>

    <Grid  container rowSpacing={1}>
    <Grid xs={2} sm={2} md={1} lg={1}  xl={1} style={{marginTop:10}}>
    <label >From:</label>
    </Grid>
        <Grid xs={10} sm={10} md={4} lg={4}  xl={4} >
      
      <DatePicker  id="startdate" name="startdate"
        selected={selectedfromDate}
        onChange={handleChangeFromDate} 
      />
    </Grid>
    <Grid xs={2} sm={2} md={1} lg={1}  xl={1}  style={{marginTop:10}}>
    <label >To:</label>
    </Grid>
    <Grid xs={10} sm={10} md={4} lg={4}  xl={4} >

      <DatePicker  id="todate" name="todate"
        selected={selectedtoDate}
        onChange={handleChangeToDate} 
      />
    </Grid>
    </Grid>
    </Box>
  );
};

export default SearchDateUi;
