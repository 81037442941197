
import React, { useEffect, useState } from "react";
import http from "../../http-common";
import { Grid } from "@mui/material";
import { LineElement, Chart as ChartJS } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { ChartOptions } from 'chart.js';

const LineCharts = () => {
  const [orders, setOrders] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get("/orders");
        const data = response.data;
        setOrders(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const cakeList = orders.map((order: any, index:any) =>
    order.orderItems.map((item: any) => item.productName).join(",")
  );
  const filterCakeList = cakeList.filter((value: any) => value !== "");

  const filterCakeList1 = filterCakeList.map((item: any) => item.split(","));

  const mergedArray = [].concat(...filterCakeList1);
  const finalList = mergedArray.sort();

  let cakeCountList: any = {};

  finalList.forEach((item) => {
    if (!cakeCountList[item]) {
      cakeCountList[item] = 1;
    } else {
      cakeCountList[item]++;
    }
  });

  ChartJS.register(LineElement);

  const LineChart = () => {
    const data = {
      labels: Object.keys(cakeCountList),
      datasets: [
        {
          label: "cake sales count",
          backgroundColor: "rgba(75, 192, 192, 1)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 2,
          pointBackgroundColor: "#000000", 
          pointRadius: 1,
          fill: 'origin',
          data: Object.values(cakeCountList),
        },
      ],
    };

    const options: ChartOptions<'line'> = {
      scales: {
        x:{
          grid: {
            display: false,
          },
          ticks: {
            display: false, 
          },
        },
        y: {
          type: "linear",
          beginAtZero: true,
          grid: {
            display: false,
          },
        },
      },
    };

    return (
      <div style={{ textAlign: 'center' }}>
        <h2>Order LineChart</h2>
        <Line height={100} data={data} options={options} />
      </div>
    );
  };

  return (
    <>
      <Grid>
        <LineChart />
      </Grid>
    </>
  );
};

export default LineCharts;
