import React from 'react'
import { RemainderBreadcrumb } from '../../components/layouts/Breadcrumb'

const RemainderSchedule = () => {
  return (
    <><RemainderBreadcrumb />
    <div>RemainderSchedule</div></>
  )
}

export default RemainderSchedule