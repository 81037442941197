import React from "react";
import SalesReport from "../layouts/SalesReport";

const Notify = () => {
  return (
    <>
      <div>Notify</div>
      <div>{<SalesReport />}</div>
    </>
  );
};

export default Notify;
