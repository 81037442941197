// import React from 'react'


import React, { useEffect, useState } from 'react';
import Orders from './newOrderComponents/Orders';
import { CircularProgress } from '@mui/material';
import { NewOrderBreadcrumb, OrderBreadcrumb } from '../../components/layouts/Breadcrumb';


const NewOrders = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <><NewOrderBreadcrumb /><div className="new-orders-container">
      {loading ? (
        <div className="loader-container">
          <CircularProgress />
        </div>
      ) : (
        <Orders />
      )}
    </div></>
  );
};

export default NewOrders;
