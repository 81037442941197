import React from 'react'
import { HistoryreportBreadcrumb } from '../../components/layouts/Breadcrumb'

const UserHistory = () => {
  return (
    <><HistoryreportBreadcrumb />
    <div>UserHistory</div></>
  )
}

export default UserHistory