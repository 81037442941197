
export default class PriceCalculation {
  weight: number;
  creamType: string;
  egglessBaseType: any;
  egglessChecked: boolean;
  shape: string;
  basePrice: number;
  finalPrice: number;

  constructor({
    weight,
    creamType,
    egglessChecked,
    egglessBaseType,
    shape,
    basePrice,
  }: any) {
    this.weight = weight;
    this.creamType = creamType;
    this.egglessChecked = egglessChecked;
    this.egglessBaseType = egglessBaseType;
    this.shape = shape;
    this.basePrice = basePrice;
    this.finalPrice = 0;
  }

  calculateprice() {
    this.updateFinalPrice(this.getPriceByWeight());
    if (this.egglessChecked) {
      this.updateFinalPrice(this.getEgglessPrice());
    }
    this.updateFinalPrice(this.getPriceByShape());
  }

  getPriceByWeight = () => {
    return this.weight * this.basePrice;
  };

  getEgglessPrice = () => {
    return this.egglessBaseType
      ? this.egglessBaseType.addOnPrice * this.weight
      : 0;
  };

  getPriceByShape = () => {
    if (this.shape !== this.getDefaultShape() && this.weight) {
      return 100 * this.weight;
    }
    return 0;
  };

  getDefaultShape = () => {
    if (this.weight >= 1.5) {
      return "Rectangle";
    } else {
      return "Round";
    }
  };

  updateFinalPrice(addOnPrice: number) {
    this.finalPrice += addOnPrice;
  }

  getFinalPrice = () => {
    return this.finalPrice;
  };
}
