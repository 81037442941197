import React from 'react'
import { OrderedproductBreadcrumb } from '../../components/layouts/Breadcrumb'

const OrderedProductList = () => {
  return (
    <><OrderedproductBreadcrumb />
    <div>OrderedProductList</div></>
  )
}

export default OrderedProductList