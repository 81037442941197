import React, { useState } from "react";
import Cards from "./Cards";
import data from "./Data";
import "./features.css";
import Orders from "../Orders/Orders";
import Carts from "../Carts/Carts";
import Status from "../Status/Status";
import Notify from "../Notify/Notify";
import Traffic from "../Traffic/Traffic";
import LineCharts from "../Charts/LineCharts";
import BarCharts from "../Charts/BarCharts";
import Coupon from "../Coupon/Coupon";
import { Grid, Card} from "@mui/material";
import {
  faUser,
  faClipboardCheck,
  faChartLine,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import TopCards from "./TopCards";
import YearSalesPieChart from "../Charts/YearSalesPieChart";


const Features = () => {
  const [page, setPage] = useState(null);

  const cardGradientColors = [
    "linear-gradient(180deg, #ec458d, #2472fc)", 
    "linear-gradient(180deg, #95f9c3, #0b3866)",
    "linear-gradient(180deg, #e62314, #f9c823)",
    "linear-gradient(180deg, #f3f520, #59d102)",
    
  ];
  const additionalCards = [
    {
      title: "User Count",
      icon: faUser,
    },
    {
      title: "Order Count",
      icon: faClipboardCheck,
    },
    {
      title: "Revenue",
      icon: faChartLine,
    },
    {
      title: "Cart Count",
      icon: faShoppingCart,
    },
  ];

  return (
    <>
      {page === 0 && <Orders />}
      {page === 1 && <Carts />}
      {page === 2 && <Status />}
      {page === 3 && <Notify />}
      {page === 4 && <Traffic />}
      {page === 5 && <LineCharts />}
      {page === 6 && <BarCharts />}
      {page === 7 && <Coupon />}

      {page === null && (
        <>
          <Grid container spacing={3} style={{ padding: "20px" }}>
            {additionalCards.map((card: any, index: any) => (
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3} key={index}>
                <TopCards
                  icon={card.icon}
                  title={card.title}
                  gradient={cardGradientColors[index]}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Card
              sx={{
              p: "20px",
              width: "500px",
              height: "250px",
              background: "#26282a",
              margin: "20px 0",
              alignItems: "center",
              borderRadius: '25px',
              color: 'white',
              marginLeft: '20px',
              marginTop: '10px',
            }}
            >
              
              <LineCharts />
            </Card>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Card
             sx={{
              p: "20px",
              width: "500px",
              height: "250px",
              background: "#26282a",
              margin: "20px 0",
              alignItems: "center",
              borderRadius: '25px',
              color: 'white',
              marginLeft: '20px',
              marginTop: '10px',
              justifyContent: 'center',
            }}
          >
            <YearSalesPieChart />
          </Card>
          </Grid>
          </Grid>

          <section className="features top" id="features">
            <div className="container">
              <div className="heading">
                <h4>CakePoint Dashboard</h4>
                <h1>Filters</h1>
              </div>

              <div className="content grid">
                {data.map((val: any, index: any) => (
                  <div key={index} onClick={() => setPage(index)}>
                    <Cards
                      key={index}
                      image={val.image}
                      title={val.title}
                      desc={val.desc}
                    />
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Features;

