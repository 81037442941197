import React from 'react'
import { CancelOrderBreadcrumb } from '../../components/layouts/Breadcrumb'

const CancelledOrders = () => {
  return (
    <><CancelOrderBreadcrumb />
    <div>CancelledOrders</div></>
  )
}

export default CancelledOrders