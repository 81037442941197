import React from 'react'
import { UserBreadcrumb } from '../../components/layouts/Breadcrumb'

const UserCreation = () => {
  return (
    <><UserBreadcrumb />
    <div>UserCreation</div></>
  )
}

export default UserCreation