import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as Lk } from "react-router-dom";
import { FaGreaterThan } from 'react-icons/fa';

//.....order management....
export const OrderBreadcrumb = () => {
  return (
    <div>
    <Breadcrumbs >
      <Typography>
        Order management 
      </Typography>
    </Breadcrumbs>
  </div>
);
};


export const NewOrderBreadcrumb = () => {
  return (
    <div>
        <Breadcrumbs >
          <Typography>
          order management &gt;
          NewOrder 
          </Typography>
        </Breadcrumbs>
      </div>
    );
  };

  export const ActiveOrderBreadcrumb = () => {
    return (
      <div>
          <Breadcrumbs >
            <Typography>
            order management &gt;
            Active order
            </Typography>
          </Breadcrumbs>
        </div>
      );
    };

    export const CompleteOrderBreadcrumb = () => {
      return (
        <div>
            <Breadcrumbs >
              <Typography>
              order management &gt;
              completed order
              </Typography>
            </Breadcrumbs>
          </div>
        );
      };

    export const CancelOrderBreadcrumb = () => {
        return (
          <div>
              <Breadcrumbs >
                <Typography>
                order management &gt;
                Cancelled order
                </Typography>
              </Breadcrumbs>
            </div>
          );
        };

        export const OrderReportBreadcrumb = () => {
          return (
            <div>
                <Breadcrumbs >
                  <Typography>
                  order management &gt;
                  Order report
                  </Typography>
                </Breadcrumbs>
              </div>
            );
          };

    //.....offer management.....
  export const OfferBreadcrumb = () => {
      return (
        <div>
            <Breadcrumbs >
              <Typography>
              Offer management 
              </Typography>
            </Breadcrumbs>
          </div>
        );
      };

  export const CouponBreadcrumb = () => {
        return (
          <div>
              <Breadcrumbs >
                <Typography>
                Offer management &gt;
                Add Coupon
                </Typography>
              </Breadcrumbs>
            </div>
          );
        };

    export const ExistingcouponBreadcrumb = () => {
          return (
            <div>
                <Breadcrumbs >
                  <Typography>
                  Offer management &gt;
                  Existing coupon
                  </Typography>
                </Breadcrumbs>
              </div>
            );
          };

    export const SpecialofferBreadcrumb = () => {
            return (
              <div>
                  <Breadcrumbs >
                    <Typography>
                    Offer management &gt;
                    Special offer
                    </Typography>
                  </Breadcrumbs>
                </div>
              );
            };

    export const InventBreadcrumb = () => {
        return (
          <div>
            <Breadcrumbs >
              <Typography>
                Inventory 
              </Typography>
            </Breadcrumbs>
          </div>
           );
        };

    export const ProductavailBreadcrumb = () => {
          return (
            <div>
                <Breadcrumbs >
                  <Typography>
                 Inventory &gt;
                 Product Available
                  </Typography>
                </Breadcrumbs>
              </div>
            );
          };

   export const OrderedproductBreadcrumb = () => {
            return (
              <div>
                  <Breadcrumbs >
                    <Typography>
                    Inventory &gt;
                    Ordered Product List
                    </Typography>
                  </Breadcrumbs>
                </div>
              );
            };
    export const ProductmanageBreadcrumb = () => {
            return (
            <div>
                <Breadcrumbs >
                      <Typography>
                      Product management
                      </Typography>
                    </Breadcrumbs>
                  </div>
                );
              };
      export const StrapiproductsBreadcrumb = () => {
            return (
              <div>
                <Breadcrumbs >
                  <Typography>
                          Product management &gt;
                          Strapi products
                    </Typography>
                    </Breadcrumbs>
                  </div>
              );
            };
      export const ProductCampBreadcrumb = () => {
              return (
                <div>
                  <Breadcrumbs >
                    <Typography>
                        Product management &gt;
                        Product Campaigns
                      </Typography>
                      </Breadcrumbs>
                    </div>
                );
              };
      export const AdminBreadcrumb = () => {
          return (
            <div>
              <Breadcrumbs >
              <Typography>
                 Administration
              </Typography>
              </Breadcrumbs>
              </div>
             );
       };
    export const RoleBreadcrumb = () => {
        return (
          <div>
            <Breadcrumbs >
            <Typography>
               Administration &gt;
               Role creation
            </Typography>
            </Breadcrumbs>
            </div>
           );
     };
     export const UserBreadcrumb = () => {
      return (
        <div>
          <Breadcrumbs >
          <Typography>
             Administration &gt;
             User creation
          </Typography>
          </Breadcrumbs>
          </div>
         );
   };

   export const PagepreviligeBreadcrumb = () => {
    return (
      <div>
        <Breadcrumbs >
        <Typography>
           Administration &gt;
           Pages Previlege
        </Typography>
        </Breadcrumbs>
        </div>
       );
 };
 export const UserpreviligeBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
         Administration &gt;
         User Previlege
      </Typography>
      </Breadcrumbs>
      </div>
     );
};
export const NotificationBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
         Administration &gt;
         Notification config
      </Typography>
      </Breadcrumbs>
      </div>
     );
};
export const ShopBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
         Administration &gt;
         Shop Maintanence
      </Typography>
      </Breadcrumbs>
      </div>
     );
};

export const ReportsBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
        Reports
      </Typography>
      </Breadcrumbs>
      </div>
     );
};
export const HistoryreportBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
         Reports &gt;
         User History report
      </Typography>
      </Breadcrumbs>
      </div>
     );
};

export const UseractionsBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
         Reports &gt;
         User Actions report
      </Typography>
      </Breadcrumbs>
      </div>
     );
};
export const OrderreportBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
         Reports &gt;
         Order management report
      </Typography>
      </Breadcrumbs>
      </div>
     );
};
export const ShopreportBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
         Reports &gt;
        Shop report
      </Typography>
      </Breadcrumbs>
      </div>
     );
};
export const NotificationreportBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
         Reports &gt;
        Notification report
      </Typography>
      </Breadcrumbs>
      </div>
     );
};
export const CartBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
        Cart management
      </Typography>
      </Breadcrumbs>
      </div>
     );
};
export const CartslistBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
        Cart management &gt;
        Carts list
      </Typography>
      </Breadcrumbs>
      </div>
     );
};
export const RemainderBreadcrumb = () => {
  return (
    <div>
      <Breadcrumbs >
      <Typography>
        Cart management &gt;
        Remainder Schedule
      </Typography>
      </Breadcrumbs>
      </div>
     );
};