import React from "react";
import FullWidthTabs from "./FullWidthTabs";
import { ActiveOrderBreadcrumb } from "../../components/layouts/Breadcrumb";

const ActiveOrders = () => {
  return (
    <>
    <ActiveOrderBreadcrumb/>
<FullWidthTabs/>
    </>
  );
};

export default ActiveOrders;
