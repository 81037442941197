import React from 'react'
import { SpecialofferBreadcrumb } from '../../components/layouts/Breadcrumb'

const SpecialOffers = () => {
  return (
    <><SpecialofferBreadcrumb />
    <div>SpecialOffers</div></>
  )
}

export default SpecialOffers