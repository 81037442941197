import axios from "axios";

export default axios.create({
  baseURL: "https://cakepoint-dashboard-backend-5226933ee6bd.herokuapp.com/",
  // baseURL: "http://localhost:4000",
  headers: {
    "Content-type": "application/json",
  },
  timeout: 20000,
});
