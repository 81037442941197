import React from 'react'
import { CartslistBreadcrumb } from '../../components/layouts/Breadcrumb'

const CartList = () => {
  return (
    <><CartslistBreadcrumb />
    <div>CartList</div></>
  )
}

export default CartList