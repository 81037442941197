import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';



const SearchWeigtUi=(props:any)=>{

const {weightArr,selectWeightoptions}=props;

return(<>
<Box>
    <Grid >
        
        <Grid xs={12} sm={12} md={12} lg={12}  xl={12} >
        <TextField  name="searchweightoptions" className='formbold-form-input' onChange={(event)=>selectWeightoptions(event)}   SelectProps={{
            native: true,
          }} select variant="filled" id="searchweightoptions"  >
   <option key="" value="">select Weight</option>  
    {weightArr.map((item:any,index:any)=>(
   <option key={index} value={item.unit}>{item.text}</option>  
    ))}
    </TextField>

        </Grid>

    </Grid>
    
</Box>
</>)
}

export default SearchWeigtUi;