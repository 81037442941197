import React from 'react';
import SearchUi from './SearchUi';
import { Grid } from '@mui/material';
import { Box } from '@mui/material';

const SearchOrders=()=>{
    return (
        <>
        <Box >

            <Grid xs={7} sm={7} md={7} lg={5} xl={7}>
            <h1>Search</h1>
            </Grid>



        <SearchUi/>
        </Box>
        </>
    )
}

export default SearchOrders;