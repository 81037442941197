import React from 'react'
import { OrderReportBreadcrumb } from '../../components/layouts/Breadcrumb'

const OrdersReport = () => {
  return (
    <><OrderReportBreadcrumb />
    <div>OrdersReport</div></>
  )
}

export default OrdersReport