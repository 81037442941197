import { useEffect, useState } from "react";
import http from "../../http-common";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

function SalesReport() {
  const [orders, setOrders] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get("/orders");
        const data = response.data;
        setOrders(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  // Total order amount
  const cakeList1 = orders.map((order: any, index: any) => order.orderPrice);
  var totalOrderPrice = 0;
  for (let i = 0; i < cakeList1.length; i++) {
    totalOrderPrice += cakeList1[i];
  }

  // create object (date and price)
  const cakeList2 = orders.map((item: any) => {
    return {
      orderDate: item.orderDate,
      orderPrice: item.orderPrice,
    };
  });

  // filter 2022 orders
  const lastYearData = cakeList2.filter(
    (item: any) => new Date(item.orderDate).getFullYear() === 2022
  );

  // filter 2023 orders
  const currentYearData = cakeList2.filter(
    (item: any) => new Date(item.orderDate).getFullYear() === 2023
  );
  console.log(lastYearData, currentYearData);

  // 2022 year total order amount
  var lastYearAmount = [];
  for (let i = 0; i < lastYearData.length; i++) {
    lastYearAmount.push(lastYearData[i].orderPrice);
  }
  var lastYearTotalAmount = 0;
  for (let i = 0; i < lastYearAmount.length; i++) {
    lastYearTotalAmount += lastYearAmount[i];
  }
  console.log("2022 total Rs.", lastYearTotalAmount);

  //  2023 year total order amount
  var currentYearAmount = [];
  for (let i = 0; i < currentYearData.length; i++) {
    currentYearAmount.push(currentYearData[i].orderPrice);
  }
  var currentYearTotalAmount = 0;
  for (let i = 0; i < currentYearAmount.length; i++) {
    currentYearTotalAmount += currentYearAmount[i];
  }
  console.log("2023 total Rs.", currentYearTotalAmount);

  function MonthSalesList(year: any) {
    //  jan month total order amount
    var jan = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 0
    );
    var janAmount = jan.map((item: any) => item.orderPrice);
    var janTotalAmount = 0;
    for (let i = 0; i < janAmount.length; i++) {
      janTotalAmount += janAmount[i];
    }

    //  feb month total order amount
    var feb = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 1
    );
    var febAmount = feb.map((item: any) => item.orderPrice);
    var febTotalAmount = 0;
    for (let i = 0; i < febAmount.length; i++) {
      febTotalAmount += febAmount[i];
    }

    //  march month total order amount
    var march = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 2
    );
    var marchAmount = march.map((item: any) => item.orderPrice);
    var marchTotalAmount = 0;
    for (let i = 0; i < marchAmount.length; i++) {
      marchTotalAmount += marchAmount[i];
    }

    //  april month total order amount
    var april = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 3
    );
    var aprilAmount = april.map((item: any) => item.orderPrice);
    var aprilTotalAmount = 0;
    for (let i = 0; i < aprilAmount.length; i++) {
      aprilTotalAmount += aprilAmount[i];
    }

    //  may month total order amount
    var may = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 4
    );
    var mayAmount = may.map((item: any) => item.orderPrice);
    var mayTotalAmount = 0;
    for (let i = 0; i < mayAmount.length; i++) {
      mayTotalAmount += mayAmount[i];
    }

    //  june month total order amount
    var june = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 5
    );
    var juneAmount = june.map((item: any) => item.orderPrice);
    var juneTotalAmount = 0;
    for (let i = 0; i < juneAmount.length; i++) {
      juneTotalAmount += juneAmount[i];
    }

    //  jully month total order amount
    var jully = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 6
    );
    var jullyAmount = jully.map((item: any) => item.orderPrice);
    var jullyTotalAmount = 0;
    for (let i = 0; i < jullyAmount.length; i++) {
      jullyTotalAmount += jullyAmount[i];
    }

    //  Augest month total order amount
    var aug = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 7
    );
    var augAmount = aug.map((item: any) => item.orderPrice);
    var augTotalAmount = 0;
    for (let i = 0; i < augAmount.length; i++) {
      augTotalAmount += augAmount[i];
    }

    //  September month total order amount
    var sept = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 8
    );
    var septAmount = sept.map((item: any) => item.orderPrice);
    var septTotalAmount = 0;
    for (let i = 0; i < septAmount.length; i++) {
      septTotalAmount += septAmount[i];
    }

    //  Octomber month total order amount
    var oct = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 9
    );
    var octAmount = oct.map((item: any) => item.orderPrice);
    var octTotalAmount = 0;
    for (let i = 0; i < octAmount.length; i++) {
      octTotalAmount += octAmount[i];
    }

    //  November month total order amount
    var nov = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 10
    );
    var novAmount = nov.map((item: any) => item.orderPrice);
    var novTotalAmount = 0;
    for (let i = 0; i < novAmount.length; i++) {
      novTotalAmount += novAmount[i];
    }

    //  December month total order amount
    var dec = year.filter(
      (item: any) => new Date(item.orderDate).getMonth() === 11
    );
    var decAmount = dec.map((item: any) => item.orderPrice);
    var decTotalAmount = 0;
    for (let i = 0; i < decAmount.length; i++) {
      decTotalAmount += decAmount[i];
    }
    // console.log("January", janTotalAmount);
    // console.log("February", febTotalAmount);
    // console.log("March", marchTotalAmount);
    // console.log("April", aprilTotalAmount);
    // console.log("May", mayTotalAmount);
    // console.log("June", juneTotalAmount);
    // console.log("jully", jullyTotalAmount);
    // console.log("Augest", augTotalAmount);
    // console.log("September", septTotalAmount);
    // console.log("October", octTotalAmount);
    // console.log("November", novTotalAmount);
    // console.log("December", decTotalAmount);

    return {
      MonthViseSales: [
        { month: "January", amount: janTotalAmount },
        { month: "February", amount: febTotalAmount },
        { month: "March", amount: marchTotalAmount },
        { month: "April", amount: aprilTotalAmount },
        { month: "May", amount: mayTotalAmount },
        { month: "June", amount: juneTotalAmount },
        { month: "Jully", amount: jullyTotalAmount },
        { month: "Augest", amount: augTotalAmount },
        { month: "September", amount: septTotalAmount },
        { month: "October", amount: octTotalAmount },
        { month: "November", amount: novTotalAmount },
        { month: "December", amount: decTotalAmount },
      ],
    };
  }

  function SalesTable(props: any) {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow
              style={{
                fontWeight: "bold",
                fontSize: "25px",
                textAlign: "right",
              }}
            >
              {props.years}
            </TableRow>
            <TableRow>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "18px" }}
                align="center"
              >
                Month
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "18px" }}
                align="center"
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.year.map((row: any) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{row.month}</TableCell>
                <TableCell align="center">{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function BasicCard(props: any) {
    return (
      <Card
        sx={{ minWidth: 100 }}
        style={{
          borderBottom: props.border,
        }}
      >
        <CardContent>
          <Typography
            sx={{ fontSize: "1.5em", textAlign: "center" }}
            color="text.secondary"
            gutterBottom
          >
            {props.order}
          </Typography>
          <Typography
            variant="h4"
            component="div"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke={props.color}
              style={{ width: "40px", height: "40px" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span></span>
            {props.amount}
            <br />
            {props.month}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          columnGap: "50px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BasicCard
          color={"#9d53c3"}
          border={"10px solid #9d53c3"}
          amount={totalOrderPrice}
          order="Total Order Amount"
        />
        <BasicCard
          color={"red"}
          border={"10px solid red"}
          amount={lastYearTotalAmount}
          order="2022 Total Order Amount"
        />
        <BasicCard
          color={"green"}
          border={"10px solid green"}
          amount={currentYearTotalAmount}
          order="2023 Total Order Amount"
        />
      </div>

      <div
        style={{
          marginTop: "50px",
          display: "flex",
          padding: "15px",
          columnGap: "15px",
        }}
      >
        <SalesTable
          year={MonthSalesList(lastYearData).MonthViseSales}
          years={2022}
        />
        <SalesTable
          year={MonthSalesList(currentYearData).MonthViseSales}
          years={2023}
        />
      </div>
    </>
  );
}

export default SalesReport;
