import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import Cart from "./Cart";
import http from "../../http-common";

const Carts = () => {
  const [carts, setCarts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(150);


 useEffect(() => {
   const fetchData = async () => {
     try {
       const response = await http.get("/users");
       const data = response.data;
       setCarts(data);
     } catch (error) {
       console.error("Error:", error);
     }
   };

   fetchData();
 }, []);

const handleChangePage = (event: any, newPage: any) => {
  setPage(newPage);
    };
    

const handleChangeRowsPerPage = (event: any) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

  return (
    <>
      <div>
        <h1>Cart Details</h1>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 100]}
          component="div"
          count={carts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Order ID</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Phone Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {carts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <Cart details={item} key={index} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Carts;
