import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { egglessBaseTypes, imageProduct, productdbs } from "../../../constants/config";
import PriceCalculation from "../../../common/PriceCalculation";

export default function BasicTable({ items, index }: any) {


  let productdbArray: any[] = [];

  for (let i = 0; i < items.length; i++) {
    if (items[i]?.products?.[0]?.productId) {
      let a = items[i].products[0].productId;
      let productdb = productdbs.find((obj: any) => obj._id.$oid === a);
      productdbArray.push(productdb);
    } else {
      let productdb = imageProduct.find(
        (obj: any) => obj.id === items[i].productId
      );
      productdbArray.push(productdb);
    }
  }

  let price = 0;
  const singlePrices: any = [];
  const calculatePriceForArrayOfObjects = (items: any) => {
    items.forEach((item: any) => {
      const egglessBaseType = item.eggless
        ? egglessBaseTypes.find((element: any) => {
            return element.baseType === item.egglessBaseFlavour;
          })
        : { baseType: "", addOnPrice: 0 };

      const priceObject = new PriceCalculation({
        weight: item.weight,
        creamType: item.type,
        egglessChecked: item.eggless,
        egglessBaseType: egglessBaseType,
        shape: item.shape,
        basePrice: item.price,
      });
      priceObject.calculateprice();
      const singleprice = Number(priceObject.finalPrice * item.count);
      singlePrices.push(singleprice);
    });

    const total = singlePrices.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue
    );
    return total;
  };

  const totalPrice = calculatePriceForArrayOfObjects(items);

  return (
    <>

        <Paper elevation={2}>
          <Grid container display="flex " justifyContent="space-between">
            <Typography sx={{ p: "10px 0px 10px 0px", fontWeight: "bold" }}>
              Items in Your Order({productdbArray.length})
            </Typography>
            <Grid xl={12} lg={12} md={12} sm={12}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Product</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <Grid container>
                          <Grid item xl={2} lg={2}>
                            <Card elevation={0}>
                              {productdbArray.map((product) => (
                                <div
                                  key={product.id}
                                  style={{ marginBottom: "8px" }}
                                >
                                  {product.images &&
                                    product.images.length > 0 && (
                                      <CardMedia
                                        sx={{ width: "50%", margin: "auto" }}
                                        component="img"
                                        image={product.images[0].image_url}
                                        alt="product image"
                                        loading="lazy"
                                      />
                                    )}
                                </div>
                              ))}
                            </Card>
                          </Grid>
                          <Grid item xl={10} lg={10}>
                            {productdbArray.map((product) => (
                              <div
                                key={product.id}
                                style={{ marginTop: "12px" }}
                              >
                                {product.productName
                                  ? product.productName
                                  : product.cake_name}
                              </div>
                            ))}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <div>
                          {items.map((item: any) => (
                            <div key={item._id}>
                              {item.count !== undefined
                                ? item.count
                                : productdbArray.map((product) => (
                                    <div key={product.id}>
                                      {product.weight
                                        ? product.weight
                                        : product.weight}
                                    </div>
                                  ))}
                            </div>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {productdbArray.map((product) => (
                          <div key={product.id}>
                            {singlePrices.length > 0 &&
                            !singlePrices.includes(NaN)
                              ? singlePrices.shift()
                              : product.price
                              ? product.price
                              : price}
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item direction="column">
              <Grid item xl={12} lg={12} md={12} sm={12} direction="column">
                <Typography
                  sx={{
                    p: "30px 0px 0px 0px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Message On cake
                </Typography>
                {items.map((item: any) => (
                  <Typography sx={{ fontSize: "13px" }} color="GrayText">
                    {item.messageOnCake ? item.messageOnCake : "Happy Birthday"}
                  </Typography>
                ))}
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} direction="column">
                <Typography
                  sx={{
                    p: "10px 0px 0px 0px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Payment Method
                </Typography>
                <Typography sx={{ fontSize: "13px" }} color="GrayText">
                  {items[index]?.paymentMethod
                    ? items[index]?.paymentMethod
                    : "COD/RazorPay"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              xl={9}
              lg={9}
              md={12}
              sm={12}
              sx={{ p: "30px 0px 0px  0px", width: "100%" }}
            >
              <Paper>
                <Box sx={{ background: "#ededed" }}>
                  <Typography sx={{ p: "10px", fontWeight: "bold" }}>
                    Order Summary
                  </Typography>
                </Box>
              </Paper>
              <Paper>
                <Grid>
                  <Grid>
                    <Typography sx={{ fontSize: "13px" }} color="GrayText">
                      Subtotal
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      {price ? price.toFixed(2) : totalPrice.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography sx={{ fontSize: "13px" }} color="GrayText">
                      18% GST (Included)
                    </Typography>
                    <Typography
                      sx={{ fontSize: "13px", fontWeight: "bold" }}
                    ></Typography>
                  </Grid>
                  <Grid>
                    <Typography sx={{ fontSize: "13px" }} color="GrayText">
                      Delivery Charges
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      Free
                    </Typography>
                  </Grid>

                  <Grid>
                    <Typography sx={{ fontSize: "13px" }} color="GrayText">
                      Total Amount
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      {price ? price.toFixed(2) : totalPrice.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
    </>
  );
}
