import React,{useState,useEffect} from 'react';
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import SearchList from './SearchList';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SearchPriceui from './SearchPriceui';
import SearchProductUi from './SearchProductUi';
import { weightArr } from '../../constants/config';
import SearchWeigtUi from './SearchWeightUi';

import SearchOrderIdUi from './SearchOrderIdUi';
import SearchDate from './SearchDate';
 let search_options=[{
    value:'Product',
    label:'Product'
},{
    value:'Price',
    label:'Price'
},
{
  value:'OrderID',
  label:'OrderID'
}
,
{
  value:'Order Date',
  label:'Order Date'
},
{
  value:'Cake Weight',
  label:'Cake Weight'
}

]



export default function SearchUi(){

    const [searchTerms,setSearchTerms]=useState();
    const [searchOrderId,setsearchOrderId]=useState();    
    const [dropdownValue,setdropdownValue]=useState('Product');
    const [minValue,setminValue]=useState(0);
    const [maxValue,setmaxValue]=useState('');
    const [itemsone, setItemsone] = useState([]);
    const [filteredOrders, setfilteredOrders] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = useState(10); 
    const [page, setPage] = useState(0); 


    const [selectedfromDate, setSelectedfromDate] = useState(new Date());
    const[selectedtoDate,setSelectedTodate]=useState(new Date());
    const[selectweightOptions,setselectWeightOptions]=useState('');
    let newarr2=useState([]);
const handleChangePage = (event: unknown, newPage: number) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event:any) => {
  setRowsPerPage(event.target.value);
  setPage(0);  
};

const handleChangeFromDate = (date:Date) => {
  setSelectedfromDate(date);
};

const handleChangeToDate=(date:Date)=>{
  setSelectedTodate(date);
}

const enterMinimumvalue=(event:any)=>{
  setminValue(event.target.value);
}

const enterMaxvalue=(event:any)=>{
    setmaxValue(event.target.value);
}

const enterSearchterms=(event:any)=>{
  setSearchTerms(event.target.value);
}

const enterSearchOrderId=(event:any)=>{
  setsearchOrderId(event.target.value);
}

const selectSearchoptions=(event:any)=>{
    setdropdownValue(event.target.value);       

}


useEffect(() => {
  fetch('https://cakepoint-dashboard-backend-5226933ee6bd.herokuapp.com/orders')
  .then((response) => response.json())
  .then((data) => {

    setItemsone(data);
  }
  
  );
    
}, []);


const selectWeightoptions=(event:any)=>{
  setselectWeightOptions(event.target.value);

}


    
const FormSubmit=(event:any)=>{
event.preventDefault();
let searchtype=dropdownValue;
if(searchtype==="Product"){
let searchparam3=searchTerms||''?.toLowerCase();
let newArray4:any[] = []
const newresult2 = itemsone.map((partOne: any,part1index:Number) => {        
return partOne.orderItems?.map((partTwo:any)=>{
let oldproductspresent=partTwo?.products?.length??0;
let newproductspresent=partTwo?.length??0;
    if(oldproductspresent>=1){
    let newpart3=partTwo.products.map((part3:any,index3:Number)=>{
    let newProductname=part3?.productName?.toLowerCase();
      if(newProductname&&newProductname.includes(searchparam3)){
        newArray4.push(partOne);
      }
    })

    }


  if(Number(newproductspresent)>=1){
  let newProductname=partTwo?.toLowerCase();
    if(newProductname&&newProductname.includes(searchparam3)){
      newArray4.push(partOne);
    }
  }



  })
    });
    setfilteredOrders(newArray4);
  }

  // OrderId Searching
  if(searchtype==="OrderID"){
    let newArray4:any[] = []
    let searchparam12=searchOrderId||'';
    let searchparam1=searchparam12?.toLowerCase();    
    const newresult2 = itemsone.map((itemslist: any) => {        
      let newOrderId=itemslist?.orderId?.toLowerCase();
      console.log("orderid"+newOrderId);
        if (newOrderId.includes(searchparam1)) {
          newArray4.push(itemslist);
        }
      });
      setfilteredOrders(newArray4);
  }



//Order Price Searching
  if(searchtype==="Price"){
    let searchparam1=minValue||0;
    let searchparam2=maxValue||0;
    let newArray3:any[] = []
    const newresult2 = itemsone.map((partOne: any,part1index:Number) => {        
        let OrderPriceValue=partOne?.orderPrice;
    if((Number(OrderPriceValue)>=Number(minValue)) && (Number(OrderPriceValue)<=Number(maxValue))){ 
      newArray3.push(partOne);
    }
        });

   setfilteredOrders(newArray3);  
  }




  // Cake Weight Searching

  if(searchtype==="Cake Weight"){
    let searchCakeWeight=selectweightOptions||'';
    let newArray4:any[] = []  
    const newresult2 = itemsone.map((partOne: any,part1index:Number) => {        
  return partOne.orderItems?.map((partTwo:any)=>{

let    oldproductspresent=partTwo?.products?.length??0;
    let newproductspresent=partTwo?.length??0;

if(oldproductspresent>=1){
  let newpart3=partTwo.products.map((part3:any,index3:Number)=>{
let newProductweight=part3?.weight;
if(newProductweight&&(newProductweight==searchCakeWeight)){  
  newArray4.push(partOne);
}
})
}

if(Number(newproductspresent)>=1){
  let newProductweight=partTwo.weight;
  if(newProductweight&&(newProductweight==searchCakeWeight)){    
    newArray4.push(partOne);
  }
  }
  })
    });
    setfilteredOrders(newArray4);
  }

//Searching Order Date
  if(searchtype=="Order Date"){

    const convfromdate = new Date(selectedfromDate);
    const convtodate = new Date(selectedtoDate);
    let newArray3:any[] = []

    let frommonth = convfromdate.getMonth()+1;
    let fromday = convfromdate.getDate();
    let fromyear = convfromdate.getFullYear();
    const formattedFromDate = new Date(fromyear+"-"+frommonth+"-"+fromday);

    let tomonth = convtodate.getMonth()+1;
    let today = convtodate.getDate();
    let toyear = convtodate.getFullYear();
    const formattedToDate = new Date(toyear+"-"+tomonth+"-"+today);

    console.log("from date"+formattedFromDate+" to date"+formattedToDate);

    const newresult2 = itemsone.map((partOne: any,part1index:Number) => {  
      let orderDate=partOne?.orderDate;
  if((orderDate>=formattedFromDate.toISOString())&& (orderDate<=formattedToDate.toISOString())){ 
    //console.log("condition satisfied"+JSON.stringify(partOne.orderDate)+"---"+JSON.stringify(partOne.orderId));    
    newArray3.push(partOne);
  }
      });
 setfilteredOrders(newArray3); 
 
  }




}    

const datalength=filteredOrders.length;
const paginatedData = filteredOrders.slice((page-1) * rowsPerPage, page * rowsPerPage);


console.log("sendind data"+ datalength);



return(
<Box   sx={{
        pr: {
          xs: "0%",
          sm: "0%",
          md: "3%",
          lg: "3%",
          xl: "3%",
        },
        marginBottom: {
          xs: "40%",
          sm: "40%",
          md: "3%",
          lg: "1%",
          xl: "1%",
        },
      }} >
<form onSubmit={(e)=>FormSubmit(e)}>

<Grid container columnSpacing={1} spacing={1} mt={1}>



      
  <Grid item xs={7}>

  {dropdownValue?dropdownValue==="Price"?<SearchPriceui enterMinimumvalue={enterMinimumvalue} minValue={minValue}  enterMaxvalue={enterMaxvalue} maxValue={maxValue} />
  :dropdownValue==="Product"?<SearchProductUi enterSearchterms={enterSearchterms} searchTerms={searchTerms} />
  :dropdownValue==="OrderID"?<SearchOrderIdUi enterSearchterms={enterSearchOrderId} searchOrderId={searchOrderId} />
  :dropdownValue==="Order Date"?<SearchDate selectedfromDate={selectedfromDate} selectedtoDate={selectedtoDate} handleChangeFromDate={handleChangeFromDate} handleChangeToDate={handleChangeToDate}  />
  :dropdownValue==="Cake Weight"?<SearchWeigtUi  weightArr={weightArr} selectWeightoptions={selectWeightoptions} />
  :<SearchProductUi />:<SearchProductUi />}
  </Grid>
  <Grid item xs={3}>
  <TextField  name="searchoptions" className='formbold-form-input' onChange={(event)=>selectSearchoptions(event)}  SelectProps={{
            native: true,
          }} select variant="filled" id="searchoptions"  >

    {search_options.map((item:any,index:any)=>(
      <option key={index} value={item.value}>{item.label}</option>  
    ))}
    </TextField>
  </Grid>
  <Grid item xs={2}>
  <Button variant="contained" id="searchSubmit" name="SearchSubmit" type="submit" >Search</Button>
  
  </Grid>
  
  </Grid>




  </form>


<Grid container columnSpacing={1} spacing={1}>



  <Grid xs={12} sm={12} md={12} lg={12} xl={12} spacing={12}  mt={4}>
    </Grid>

    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
<SearchList datalength={datalength} rowsdata={paginatedData}  page={page} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
</Grid>


</Grid>

</Box>
);
}