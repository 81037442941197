import { useState } from "react";
import Grid from "@mui/material/Grid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, CardMedia, Link, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import TableProduct from "./TableProduct";
import Typography from "@mui/material/Typography";
import { imageProduct, productdbs } from "../../../constants/config";
import http from "../../../http-common";

const OrderItem = ({ item, index, type }: any) => {
  let arr: any[] = [];
  const [value, setValue] = useState<boolean>(false);
  const [showReason, setShowReason] = useState<any>(false);
  const [reason, setReason] = useState<any>("");
  const orderId = item.orderId

  item.orderItems.forEach((order: any) => {
    let paymentMethod = item.paymentMethod
      ? item.paymentMethod
      : "COD/Razorpay";
    let orderWithPayment = { ...order, paymentMethod };
    arr.push(orderWithPayment);
  });

  let newProductdbArray = [];

  for (let i = 0; i < arr.length; i++) {
    if (arr[i]?.products?.[0]?.productId) {
      let a = arr[i].products[0].productId;
      let productdb = productdbs.find((obj: any) => obj?._id?.$oid === a);
      newProductdbArray.push(productdb);
    } else {
      let productdb = imageProduct.find(
        (obj: any) => obj.id === arr[i].productId
      );
      newProductdbArray.push(productdb);
    }
  }

  let imageUrl = newProductdbArray.map((product: any) => {
    if (product && product?.images && product?.images.length > 0) {
      return product?.images[0].image_url;
    } else {
      return null;
    }
  });



  function handleClick() {
    setValue((prevValue: any) => !prevValue);
  }

  const handleAccept = async() => {
     try {
      const response = await http.post("/orderlogs/orderconfirm", {
      orderId:orderId
    })
      const result: any = response.data;
      console.log(result.message); 
    } catch (error: any) {
      console.error("Error login:", error.response.data.message);
      alert(error.response.data.message);
    }
    setShowReason(false);
    setReason(null);
  };
  const handlePrepare = async() => {
     try {
      const response = await http.post("/orderlogs/orderpreparing", {
      orderId:orderId
    })
      const result: any = response.data;
      console.log(result.message); 
    } catch (error: any) {
      console.error("Error login:", error.response.data.message);
      alert(error.response.data.message);
    }
    setShowReason(false);
    setReason(null);
  };
  const handlePrepared = async() => {
     try {
      const response = await http.post("/orderlogs/orderprepared", {
      orderId:orderId
    })
      const result: any = response.data;
      console.log(result.message); 
    } catch (error: any) {
      console.error("Error login:", error.response.data.message);
      alert(error.response.data.message);
    }
    setShowReason(false);
    setReason(null);
  };
  const handleout = async() => {
     try {
      const response = await http.post("/orderlogs/orderout", {
      orderId:orderId
    })
      const result: any = response.data;
      console.log(result.message); 
    } catch (error: any) {
      console.error("Error login:", error.response.data.message);
      alert(error.response.data.message);
    }
    setShowReason(false);
    setReason(null);
  };
  const handledelivered = async() => {
     try {
      const response = await http.post("/orderlogs/orderdelivered", {
        orderId: orderId,
      });
      const result: any = response.data;
      console.log(result.message); 
    } catch (error: any) {
      console.error("Error login:", error.response.data.message);
      alert(error.response.data.message);
    }
    setShowReason(false);
    setReason(null);
  };

  const handleCancel = async() => {
    setShowReason(!showReason)
    
  }

  const confirmCancel = async() => {
     try {
       const response = await http.post("/orderlogs/ordercancel", {
         orderId: orderId,
         cancelReason: reason,
       });
       const result: any = response.data;
       console.log(result.message);
     } catch (error: any) {
       console.error("Error login:", error.response.data.message);
       alert(error.response.data.message);
     }
    setReason("");
  }

  return (
    <>
      <Paper elevation={2}>
        <Grid container>
          <Grid container xs={10} sm={10} md={10} lg={10} xl={10}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography fontWeight={600} noWrap>
                Id :{item.orderId}
              </Typography>
            </Grid>

            <Grid lg={4} xl={4} sm={6} xs={6}>
              <Typography
                sx={{ fontSize: "13px", p: "0px 0px 0px 30px" }}
                color="GrayText"
              >
                Order Placed Date
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  p: "0px 0px 0px 30px",
                }}
              >
                {item.orderDate.slice(0, 10)}
              </Typography>
            </Grid>
            <Grid lg={2} xl={2} sm={6} xs={6}>
              <Typography sx={{ fontSize: "13px" }} color="GrayText">
                Total
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
                ₹ {item.orderPrice.toFixed(2)}
              </Typography>
            </Grid>
            <Grid lg={5} xl={5} sm={12} xs={12}>
              <Typography
                sx={{ fontSize: "13px", p: "0px 0px 0px 30px" }}
                color="GrayText"
              >
                Delivered To
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  p: "0px 0px 0px 30px",
                }}
              >
                {item.deliveryAddress.address},{item.deliveryAddress.pinCode}
              </Typography>
            </Grid>
            <Grid lg={8} xl={8}>
              <Typography
                sx={{ fontSize: "13px", p: "10px 0px 10px 30px" }}
                color="GrayText"
              >
                Delivery Date
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  p: "0px 0px 0px 30px",
                }}
              >
                {item.deliveryDateAndTime}
              </Typography>
            </Grid>
            <Grid
              lg={12}
              xl={12}
              sx={{ fontSize: "13px", p: "0px 0px 0px 30px" }}
            >
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {imageUrl.map((product: any) => (
                  <div key={product} style={{ width: "15%" }}>
                    {product && (
                      <CardMedia
                        component="img"
                        image={product}
                        alt="product image"
                        loading="lazy"
                      />
                    )}
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            sx={{ display: "flex" }}
          >
            {/* <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography fontWeight={600} noWrap>
                Acknowledge
              </Typography>
            </Grid> */}
            {
              type === "neworder" && (
                <>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ justifyContent: "left", p: "0px 0px 0px 0px" }}
                onClick={handleAccept}
              >
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: "130px" }}
                >
                  Accept
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ p: "0px 100px 0px 0px" }}
                onClick={handleCancel}
              >
                <Button
                  variant="contained"
                  sx={{ background: "#f7e307", width: "130px" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
                </>
              )
            }
            {
              type === "active" && (
                <>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ justifyContent: "left", p: "0px 0px 0px 0px" }}
                onClick={handlePrepare}
              >
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: "130px" }}
                >
                  Mark as Preparing
                </Button>
              </Grid>
              {/* <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ p: "0px 100px 0px 0px" }}
                onClick={handleCancel}
              >
                <Button
                  variant="contained"
                  sx={{ background: "#f7e307", width: "130px" }}
                >
                  Cancel
                </Button>
              </Grid> */}
            </Grid>
                </>
              )
            }
            {
              type === "p2p" && (
                <>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ justifyContent: "left", p: "0px 0px 0px 0px" }}
                onClick={handlePrepared}
              >
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: "130px" }}
                >
                  MArk as Prepared
                </Button>
              </Grid>
              {/* <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ p: "0px 100px 0px 0px" }}
                onClick={handleCancel}
              >
                <Button
                  variant="contained"
                  sx={{ background: "#f7e307", width: "130px" }}
                >
                  Cancel
                </Button>
              </Grid> */}
            </Grid>
                </>
              )
            }
            {
              type === "p2d" && (
                <>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ justifyContent: "left", p: "0px 0px 0px 0px" }}
                onClick={handleout}
              >
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: "130px" }}
                >
                  Out for Delivery
                </Button>
              </Grid>
              {/* <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ p: "0px 100px 0px 0px" }}
                onClick={handleCancel}
              >
                <Button
                  variant="contained"
                  sx={{ background: "#f7e307", width: "130px" }}
                >
                  Cancel
                </Button>
              </Grid> */}
            </Grid>
                </>
              )
            }
            {
              type === "delivered" && (
                <>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ justifyContent: "left", p: "0px 0px 0px 0px" }}
                onClick={handledelivered}
              >
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: "130px" }}
                >
                  Delivered
                </Button>
              </Grid>
              {/* <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ p: "0px 100px 0px 0px" }}
                onClick={handleCancel}
              >
                <Button
                  variant="contained"
                  sx={{ background: "#f7e307", width: "130px" }}
                >
                  Cancel
                </Button>
              </Grid> */}
            </Grid>
                </>
              )
            }
            {showReason && (
              <>
                <TextField
                  id="outlined-basic"
                  label="Cancel Reason"
                  variant="outlined"
                  value={reason}
                  onChange={(e: any) => setReason(e.target.value)}
                />
                <Button variant="contained" color="error" onClick={confirmCancel}>
                  Confirm Cancel
                </Button>
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={5} sm={3} md={3} lg={3} xl={3}>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "bold",
              cursor: "pointer",
              textAlign: "left",
              p: "0px 0px 0px 0px",
            }}
            color={"#de3838"}
            noWrap
            justifyContent={"center"}
            onClick={handleClick}
          >
            View Details
            {!value && (
              <Link>
                <KeyboardArrowDownIcon
                  sx={{ p: "5px 0px 0px 0px", color: "#de3838" }}
                />
              </Link>
            )}
            {value && (
              <Link>
                <KeyboardArrowUpIcon
                  sx={{ p: "5px 0px 0px 0px", color: "#de3838" }}
                />
              </Link>
            )}
          </Typography>
        </Grid>
      </Paper>

      {value && arr.length > 0 ? (
        <TableProduct
          key={arr.map((item) => item._id).join(",")}
          items={arr}
          index={index}
        />
      ) : null}
    </>
  );
};

export default OrderItem;
