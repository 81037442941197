import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSignal,
  faBell,
  faClipboardList,
  faExchangeAlt,
  faTicketAlt,
  faGift,
  faTimesCircle,
  faBullhorn,
  faBox,
  faNoteSticky,
  faUser,
  faShop,
  faUsers,
  faMoneyBill,
  faHomeUser,
  faAddressBook,
  faAddressCard,
  faCartShopping,
  faCalendarCheck,
  faCheckCircle,
  faCheckToSlot,
  faGears,
} from "@fortawesome/free-solid-svg-icons";
import "../Features/features.css";
import ActiveOrders from "../../menuComponents/ordersMenu/ActiveOrders";
import NewOrders from "../../menuComponents/ordersMenu/NewOrders";
import CompletedOrders from "../../menuComponents/ordersMenu/CompletedOrders";
import CancelledOrders from "../../menuComponents/ordersMenu/CancelledOrders";
import OrdersReport from "../../menuComponents/ordersMenu/OrdersReport";
import OrderedProductList from "../../menuComponents/inventeryMenu/OrderedProductList";
import ProductAvailable from "../../menuComponents/inventeryMenu/ProductAvailable";
import Coupon from "../../menuComponents/offersMenu/Coupon";
import ExistingCoupon from "../../menuComponents/offersMenu/ExistingCoupon";
import SpecialOffers from "../../menuComponents/offersMenu/SpecialOffers";
import ProductCampaign from "../../menuComponents/productMenu/ProductCampaign";
import StrapiProducts from "../../menuComponents/productMenu/StrapiProducts";
import NotificationConfig from "../../menuComponents/administrationMenu/NotificationConfig";
import PagesPrevilage from "../../menuComponents/administrationMenu/PagesPrevilage";
import RoleCreation from "../../menuComponents/administrationMenu/RoleCreation";
import ShopMaintain from "../../menuComponents/administrationMenu/ShopMaintain";
import UserCreation from "../../menuComponents/administrationMenu/UserCreation";
import UserPrevilge from "../../menuComponents/administrationMenu/UserPrevilge";
import FullOrdersReport from "../../menuComponents/reportsMenu/FullOrdersReport";
import NotificationReport from "../../menuComponents/reportsMenu/NotificationReport";
import ShopReport from "../../menuComponents/reportsMenu/ShopReport";
import UserActionReport from "../../menuComponents/reportsMenu/UserActionReport";
import UserHistory from "../../menuComponents/reportsMenu/UserHistory";
import CartList from "../../menuComponents/cartsMenu/CartList";
import RemainderSchedule from "../../menuComponents/cartsMenu/RemainderSchedule";
import { Grid } from "@mui/material";
import { AdminBreadcrumb, CartBreadcrumb, InventBreadcrumb, OfferBreadcrumb, OrderBreadcrumb, ProductmanageBreadcrumb, ReportsBreadcrumb } from "./Breadcrumb";



const order = [
  {
    title: "New Orders",
    icon: faClipboardList,
    bgColor: "#006fb9", 
  },
  {
    title: "Active Orders",
    icon: faGears,
    bgColor: "#4CAF50", 
  },
  {
    title: "Completed Orders",
    icon: faSignal,
    bgColor: "#FF9800", 
  },
  {
    title: "Cancelled Orders",
    icon: faTimesCircle,
    bgColor: "#db5395", 
  },
  {
    title: "Orders Report",
    icon: faExchangeAlt,
    bgColor: "#340a57", 
  },
  
];
const Inventory = [
    {
      title: "Ordered Product List",
      icon: faCheckCircle,
      bgColor: "#006fb9", 
    },
    {
      title: "Product Available",
      icon: faCheckToSlot,
      bgColor: "#4CAF50", 
    },
  ];
  const offer = [
    {
      title: "Coupon",
      icon: faTicketAlt,
      bgColor: "#006fb9", 
    },
    {
      title: "Existing Coupon",
      icon: faMoneyBill,
      bgColor: "#4CAF50", 
    },
    {
        title: "Special Offers",
        icon: faGift,
        bgColor: "#340a57", 
    },
  ];
  const Product = [
    {
      title: "Product Campaign",
      icon: faBullhorn,
      bgColor: "#006fb9", 
    },
    {
      title: "Strapi Products",
      icon: faBox,
      bgColor: "#4CAF50", 
    },
  ];
  const admin = [
    {
      title: "Notification Config",
      icon: faBell,
      bgColor: "#3c44aa", 
    },
    {
      title: "Pages Previlage",
      icon: faNoteSticky,
      bgColor: "#4CAF50", 
    },
    {
      title: "Role Creation",
      icon: faUsers,
      bgColor: "#FF9800", 
    },
    {
        title: "Shop Maintainence",
        icon: faShop,
        bgColor: "#d8168e", 
    },
    {
        title: "User Creation",
        icon: faUser,
        bgColor: "#0b91a6", 
    },
    {
        title: "User Previlage",
        icon: faHomeUser,
        bgColor: "#340a57", 
    },
  ];
  const reports = [
    {
      title: "FullOrders Report",
      icon: faClipboardList,
      bgColor: "#3c44aa", 
    },
    {
      title: "Notification Report",
      icon: faBell,
      bgColor: "#4CAF50", 
    },
    {
      title: "Shop Report",
      icon: faShop,
      bgColor: "#FF9800", 
    },
    {
        title: "UserAction Report",
        icon: faAddressBook,
        bgColor: "#d8168e", 
    },
    {
        title: "User History",
        icon: faAddressCard,
        bgColor: "#0b91a6", 
    },
  ];
  const carts = [
    {
      title: "Cart List",
      icon: faCartShopping,
      bgColor: "#006fb9", 
    },
    {
      title: "Remainder Schedule",
      icon: faCalendarCheck,
      bgColor: "#4CAF50", 
    },
  ];

const Home = ({type}:any) => {
  const [page, setPage] = useState(null);
 

  return (<>
  {
    type ==="order" && (<>
     
      {page === 0 && <NewOrders />}
      {page === 1 && <ActiveOrders />}
      {page === 2 && <CompletedOrders/>}
      {page === 3 && <CancelledOrders />}
      {page === 4 && <OrdersReport />}
      {page === 5 && <Home/>}
      

      {page === null && (
        <>
        <OrderBreadcrumb/>
          <Grid className="features top" id="features">
            <div className="container">
              <div className="content grid">
                {order.map((val, index:any) => (                 
                  <div key={index} onClick={() => setPage(index)}>
                    <div
                      className="card"
                      style={{
                        border: "none",
                        height: "200px",
                        borderRadius: "15px",
                        marginBottom: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        transition: "transform 0.3s",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        position: "relative",
                        overflow: "hidden",
                        backgroundColor: val.bgColor,
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ textAlign: "center", padding: "50px" }}
                      >
                        <FontAwesomeIcon
                          icon={val.icon}
                          className="card-icon"
                          style={{
                            fontSize: "3em",
                            color: "#e0e8f1",
                          }}
                        />
                        <h5
                          className="card-title"
                          style={{
                            marginTop: "10px",
                            marginBottom: "0",
                            fontSize: "1.5em",
                            color: "#fff",
                          }}
                        >
                          {val.title}
                        </h5>
                        
                        <p
                          className="card-text"
                          style={{
                            color: "#fff",
                          }}
                        >
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
               
              </div>
            </div>
          </Grid>
        </>
      )}
    </>
    )}
    {type === "invent" && (
        <>
          {page === 0 && <OrderedProductList />}
          {page === 1 && <ProductAvailable />}
          

          {page === null && (
            <>
            <InventBreadcrumb/>
              <section className="features top" id="features">
                <div className="container">
                  <div className="content grid">
                    {Inventory.map((val, index: any) => (
                      <div key={index} onClick={() => setPage(index)}>
                        <div
                          className="card"
                          style={{
                            border: "none",
                            height: "200px",
                            borderRadius: "15px",
                            marginBottom: "20px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.3s",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: val.bgColor,
                          }}
                        >
                          <div
                            className="card-body"
                            style={{ textAlign: "center", padding: "50px" }}
                          >
                            <FontAwesomeIcon
                              icon={val.icon}
                              className="card-icon"
                              style={{
                                fontSize: "3em",
                                color: "#e0e8f1",
                              }}
                            />
                            <h5
                              className="card-title"
                              style={{
                                marginTop: "10px",
                                marginBottom: "0",
                                fontSize: "1.5em",
                                color: "#fff",
                              }}
                            >
                              {val.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
       {type === "offermanage" && (
        <>
          {page === 0 && <Coupon />}
          {page === 1 && <ExistingCoupon />}
          {page === 2 && <SpecialOffers />}

          {page === null && (
            <>
            <OfferBreadcrumb/>
              <section className="features top" id="features">
                <div className="container">
                  <div className="content grid">
                    {offer.map((val, index: any) => (
                      <div key={index} onClick={() => setPage(index)}>
                        <div
                          className="card"
                          style={{
                            border: "none",
                            height: "200px",
                            borderRadius: "15px",
                            marginBottom: "20px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.3s",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: val.bgColor,
                          }}
                        >
                          <div
                            className="card-body"
                            style={{ textAlign: "center", padding: "50px" }}
                          >
                            <FontAwesomeIcon
                              icon={val.icon}
                              className="card-icon"
                              style={{
                                fontSize: "3em",
                                color: "#e0e8f1",
                              }}
                            />
                            <h5
                              className="card-title"
                              style={{
                                marginTop: "10px",
                                marginBottom: "0",
                                fontSize: "1.5em",
                                color: "#fff",
                              }}
                            >
                              {val.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
       {type === "Productmanage" && (
        <>
          {page === 0 && <ProductCampaign />}
          {page === 1 && <StrapiProducts />}

          {page === null && (
            <>
             <ProductmanageBreadcrumb/>
              <section className="features top" id="features">
                <div className="container">
                  <div className="content grid">
                    {Product.map((val, index: any) => (
                      <div key={index} onClick={() => setPage(index)}>
                        <div
                          className="card"
                          style={{
                            border: "none",
                            height: "200px",
                            borderRadius: "15px",
                            marginBottom: "20px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.3s",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: val.bgColor,
                          }}
                        >
                          <div
                            className="card-body"
                            style={{ textAlign: "center", padding: "50px" }}
                          >
                            <FontAwesomeIcon
                              icon={val.icon}
                              className="card-icon"
                              style={{
                                fontSize: "3em",
                                color: "#e0e8f1",
                              }}
                            />
                            <h5
                              className="card-title"
                              style={{
                                marginTop: "10px",
                                marginBottom: "0",
                                fontSize: "1.5em",
                                color: "#fff",
                              }}
                            >
                              {val.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
       {type === "Administration" && (
        <>
          {page === 0 && <NotificationConfig />}
          {page === 1 && <PagesPrevilage />}
          {page === 2 && <RoleCreation />}
          {page === 3 && <ShopMaintain />}
          {page === 4 && <UserCreation />}
          {page === 5 && <UserPrevilge />}


          {page === null && (
            <>
            <AdminBreadcrumb/>
              <section className="features top" id="features">
                <div className="container">
                  <div className="content grid">
                    {admin.map((val, index: any) => (
                      <div key={index} onClick={() => setPage(index)}>
                        <div
                          className="card"
                          style={{
                            border: "none",
                            height: "200px",
                            borderRadius: "15px",
                            marginBottom: "20px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.3s",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: val.bgColor,
                          }}
                        >
                          <div
                            className="card-body"
                            style={{ textAlign: "center", padding: "50px" }}
                          >
                            <FontAwesomeIcon
                              icon={val.icon}
                              className="card-icon"
                              style={{
                                fontSize: "3em",
                                color: "#e0e8f1",
                              }}
                            />
                            <h5
                              className="card-title"
                              style={{
                                marginTop: "10px",
                                marginBottom: "0",
                                fontSize: "1.5em",
                                color: "#fff",
                              }}
                            >
                              {val.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
      {type === "Reportsmanage" && (
        <>
          {page === 0 && <FullOrdersReport />}
          {page === 1 && <NotificationReport />}
          {page === 3 && <ShopReport />}
          {page === 4 && <UserActionReport />}
          {page === 5 && <UserHistory />}


          {page === null && (
            <>
            <ReportsBreadcrumb/>
              <section className="features top" id="features">
                <div className="container">
                  <div className="content grid">
                    {reports.map((val, index: any) => (
                      <div key={index} onClick={() => setPage(index)}>
                        <div
                          className="card"
                          style={{
                            border: "none",
                            height: "200px",
                            borderRadius: "15px",
                            marginBottom: "20px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.3s",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: val.bgColor,
                          }}
                        >
                          <div
                            className="card-body"
                            style={{ textAlign: "center", padding: "50px" }}
                          >
                            <FontAwesomeIcon
                              icon={val.icon}
                              className="card-icon"
                              style={{
                                fontSize: "3em",
                                color: "#e0e8f1",
                              }}
                            />
                            <h5
                              className="card-title"
                              style={{
                                marginTop: "10px",
                                marginBottom: "0",
                                fontSize: "1.5em",
                                color: "#fff",
                              }}
                            >
                              {val.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
       {type === "Cartsmanage" && (
        <>
          {page === 0 && <CartList />}
          {page === 1 && <RemainderSchedule />}


          {page === null && (
            <>
            <CartBreadcrumb/>
              <section className="features top" id="features">
                <div className="container">
                  <div className="content grid">
                    {carts.map((val, index: any) => (
                      <div key={index} onClick={() => setPage(index)}>
                        <div
                          className="card"
                          style={{
                            border: "none",
                            height: "200px",
                            borderRadius: "15px",
                            marginBottom: "20px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.3s",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: val.bgColor,
                          }}
                        >
                          <div
                            className="card-body"
                            style={{ textAlign: "center", padding: "50px" }}
                          >
                            <FontAwesomeIcon
                              icon={val.icon}
                              className="card-icon"
                              style={{
                                fontSize: "3em",
                                color: "#e0e8f1",
                              }}
                            />
                            <h5
                              className="card-title"
                              style={{
                                marginTop: "10px",
                                marginBottom: "0",
                                fontSize: "1.5em",
                                color: "#fff",
                              }}
                            >
                              {val.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
  </>

  );
};

export default Home;
