import React from 'react'
import Coupon from '../../components/Coupon/Coupon'
import { CouponBreadcrumb } from '../../components/layouts/Breadcrumb'

const AddCoupon = () => {
  return (
    <>
    <CouponBreadcrumb/>
      <Coupon/>
    </>
  )
}

export default AddCoupon