import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import OrderItem from "./OrderItem";
import http from "../../../http-common";

const OrderItems = ({ type }: any) => {
  const [orders, setOrders] = useState<any>([]);
  const [orderlogs, setOrderlogs] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get("/orders");
        const data = response.data;
        setOrders(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    const fetchlogData = async () => {
      try {
        const response = await http.get("/orderlogs");
        const data = response.data;
        setOrderlogs(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
    fetchlogData();
  }, []);

  function newOrdersList(array1: any, array2: any) {
    const resultArray = [];
     if (type === "neworder") {
       for (const obj1 of array1) {
         for (const obj2 of array2) {
           if (
             obj1.orderId === obj2.orderId &&
             obj2.orderConfirmed === undefined &&
             obj2.ordercancelled !== true
           ) {
             resultArray.push({ ...obj1, ...obj2 });
           }
         }
       }
    }
    
    if (type === "active" ) {
      for (const obj1 of array1) {
        for (const obj2 of array2) {
          if (
            obj1.orderId === obj2.orderId &&
            obj2.orderConfirmed === true &&
            obj2.ordercancelled !== true &&
            obj2.orderPreparing === undefined
          ) {
            resultArray.push({ ...obj1, ...obj2 });
          }
        }
      }
    }
    if (type === "p2p") {
      for (const obj1 of array1) {
        for (const obj2 of array2) {
          if (
            obj1.orderId === obj2.orderId &&
            obj2.orderConfirmed === true &&
            obj2.ordercancelled !== true &&
            obj2.orderPreparing === true &&
            obj2.orderPrepared === undefined
          ) {
            resultArray.push({ ...obj1, ...obj2 });
          }
        }
      }
    }
    if (type === "p2d" ) {
      for (const obj1 of array1) {
        for (const obj2 of array2) {
          if (
            obj1.orderId === obj2.orderId &&
            obj2.orderConfirmed === true &&
            obj2.ordercancelled !== true &&
            obj2.orderPreparing === true &&
            obj2.orderPrepared === true &&
            obj2.outForDelivery === undefined

          ) {
            resultArray.push({ ...obj1, ...obj2 });
          }
        }
      }
    }
    if (type === "delivered" ) {
      for (const obj1 of array1) {
        for (const obj2 of array2) {
          if (
            obj1.orderId === obj2.orderId &&
            obj2.orderConfirmed === true &&
            obj2.ordercancelled !== true &&
            obj2.outForDelivery === true &&
            obj2.orderDelivered === undefined
          ) {
            resultArray.push({ ...obj1, ...obj2 });
          }
        }
      }
    }
   

    return resultArray;
  }

  const finalNewOrder = newOrdersList(orders, orderlogs);

  return (
    <Box
      sx={{
        pr: {
          xs: "0%",
          sm: "0%",
          md: "3%",
          lg: "3%",
          xl: "3%",
        },
        marginBottom: {
          xs: "40%",
          sm: "40%",
          md: "3%",
          lg: "1%",
          xl: "1%",
        },
      }}
    >
      {finalNewOrder &&
        finalNewOrder
          .slice()
          .reverse()
          .map((item: any, index: any) => {
            return <OrderItem item={item} index={index} type={ type} />;
          })}
    </Box>
  );
};

export default OrderItems;
