import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const SearchListItem=(props:any)=>{

    const {item,page,rowsPerPage,result_length,datalength}=props;


    
    let objectLength=item?.orderItems?.length || 0; //scenario 1   
    let objectsProductslength=item?.orderItems?.products?.length || 0;  //scenario 2
    let objectSingleProductslength=item.orderItems?.[0]?.products?.length||0;     //scenario 3

    console.log("1scenario@@"+objectLength+" @2scenarion"+objectsProductslength+" @@3length"+objectSingleProductslength);

    if(objectLength>=1 && objectsProductslength==0 && objectSingleProductslength==0){
      return  item?.orderItems?.map((proddet:any,index2:number)=>{   
              const convdate = new Date(item.orderDate);
        let month = convdate.toLocaleString("default", { month: "long" });
        let day = convdate.getDate();
        let year = convdate.getFullYear();
        const formattedDate = month + " " + day + ", " + year;
      return(
      
          <TableRow  >
                    <TableCell>{item.orderId}</TableCell>
            <TableCell>{proddet?.productName}</TableCell>
      
            <TableCell>{proddet?.productId}</TableCell>
            <TableCell>{proddet?.price}</TableCell>
            <TableCell>{proddet?.count}</TableCell>
            <TableCell>{proddet?.weight}</TableCell>
            <TableCell>{proddet?.price}</TableCell>
            <TableCell>{formattedDate}</TableCell>
          </TableRow>
        )
 
      })
      
      
      }

      

if(objectLength>=1 && objectsProductslength>=1 && objectSingleProductslength==0){
return  item?.orderItems?.map((proddet:any,index2:number)=>{
 return proddet.products.map((prodet3:any,index3:number)=>{
      const convdate = new Date(item.orderDate);
      let month = convdate.toLocaleString("default", { month: "long" });
      let day = convdate.getDate();
      let year = convdate.getFullYear();
      const formattedDate = month + " " + day + ", " + year;     
return(

    <TableRow  >
              <TableCell>{item.orderId}</TableCell>
      <TableCell>{prodet3?.productName}</TableCell>

      <TableCell>{prodet3?.productId}</TableCell>
      <TableCell>{prodet3?.productName}</TableCell>
      <TableCell>{prodet3?.count}</TableCell>
      <TableCell>{prodet3?.weight}</TableCell>
      <TableCell>{prodet3?.price}</TableCell>
      <TableCell>{item.orderDate.toString("dddd, MMMM Do YYYY")}</TableCell>
    </TableRow>
  )
})



    
})

}

if(objectLength>=1 && objectsProductslength==0 && objectSingleProductslength>=1){
  return  item?.orderItems?.map((proddet:any,index2:number)=>{
       return proddet?.products.map((prodet3:any,index3:number)=>{
        const convdate = new Date(item.orderDate);
        let month = convdate.toLocaleString("default", { month: "long" });
        let day = convdate.getDate();
        let year = convdate.getFullYear();
        const formattedDate = month + " " + day + ", " + year;    
        console.log("scenarion 2");    
  return(
  
      <TableRow >
                <TableCell>{item.orderId}</TableCell>
        <TableCell>{prodet3?.productName}</TableCell>
  
        <TableCell>{prodet3?.productId}</TableCell>
        <TableCell>{prodet3?.productName}</TableCell>
        <TableCell>{prodet3?.count}</TableCell>
        <TableCell>{prodet3?.weight}</TableCell>
        <TableCell>{prodet3?.price}</TableCell>
        <TableCell>{item.orderDate.toString("dddd, MMMM Do YYYY")}</TableCell>
      </TableRow>
    )
  })
  
  
  
      
  })
  
  }

  if(objectLength==0 && objectsProductslength==0 && objectSingleProductslength==0){
return(<TableRow>
  <TableCell colSpan={8} align="center">No Data Found</TableCell>
  </TableRow>)

  }






}


export default SearchListItem;