import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TopCards = (props: any) => {
  return (
    <>
    
      <div
        className="box btn_shadow"
        style={{
          background: props.gradient,
          border: "none",
          borderRadius: "15px",
          marginBottom: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s",
          position: "relative",
          overflow: "hidden",
          height: "250px",
          padding: "30px",
          display: "flex",  // Added to make it a flex container
          flexDirection: "column", // To stack child elements vertically
          alignItems: "start", // Align items horizontally at the center
          justifyContent: "center", // Align items vertically at the center
        }}
      >
        <FontAwesomeIcon
          icon={props.icon}
          className="card-icon"
          style={{
            fontSize: "3em",
            color: "#e0e8f1",
          }}
        />
        <img
          src={props.image}
          alt=""
          style={{
            filter: "brightness(0) invert(1)",
          }}
        />
        <h2 style={{ color: "white" }}>{props.title}</h2>
        <p style={{ color: "white" }}>{props.desc}</p>
        
      </div>
    </>
  );
};

export default TopCards;

