import React  from 'react'
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import SearchListItem from './SearchListItem';




const itemsarray: string[] = [];


const SearchList=(props:any)=>{
    const theme = useTheme();
 
    const {rowsdata,page,rowsPerPage,handleChangePage,handleChangeRowsPerPage,datalength} = props;
const result_length=rowsdata.length;

console.log("Search List length @@"+datalength);

    return (
        <>
<Box sx={{ bgcolor: "background.paper" }}>
  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
             <TableCell>OrderId</TableCell>
             <TableCell>Name</TableCell>
             <TableCell>Product Id</TableCell>
             <TableCell>Price</TableCell>
             <TableCell>Qty</TableCell>
             <TableCell>Weight</TableCell>
             <TableCell>Price</TableCell>
             <TableCell>Order Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            


          {
          
          rowsdata.length?rowsdata
          .map((item: any, index: any) => {
return <SearchListItem item={item} index={index}  page={page} rowsPerPage={rowsPerPage} result_length={datalength}  />
      }):  <TableRow>
<TableCell colSpan={8} align="center">No Data Found</TableCell>
</TableRow>
   }

          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={datalength}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
</Box>

    </>
    )
}

export default SearchList;