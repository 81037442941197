const data = [
  {
    id: 1,
    image: "https://img.icons8.com/ios/64/000000/menu.png",
    title: "Order list",
    desc: "Streamline the orders with our user-friendly Order List tab.",
  },
  {
    id: 2,
    image: "https://img.icons8.com/ios/40/000000/open-book.png",
    title: "Cart List",
    desc: " Streamline the Carts with our user-friendly Cart List tab.",
  },
  {
    id: 3,
    image:
      "https://img.icons8.com/fluency-systems-regular/64/000000/retro-tv.png ",
    title: "Track Status",
    desc: "Track Payment status, Delivery status here.",
  },
  {
    id: 4,
    image: "https://img.icons8.com/material-outlined/64/000000/topic--v1.png ",
    title: "Notifications",
    desc: "Notify status to customers here.",
  },
  {
    id: 5,
    image: "https://img.icons8.com/ios-filled/64/000000/wifi.png ",
    title: "Order Traffic",
    desc: "Detailed Report of all orders and its traffic reports.",
  },
  {
    id: 6,
    image: "https://img.icons8.com/glyph-neue/50/000000/polyline.png ",
    title: "Order Charts",
    desc: " Charts based on order history.",
  },
  {
    id: 7,
    image: "https://img.icons8.com/metro/52/000000/add-ticket.png",
    title: "Coupon Codes",
    desc: "Coupons for discount",
  },
];
export default data;
