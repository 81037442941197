import React from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
// import Orders from "./components/Orders/Orders";
// import Carts from "./components/Carts/Carts";
// import Notify from "./components/Notify/Notify";
// import Status from "./components/Status/Status";
// import Traffic from "./components/Traffic/Traffic";
// import Charts from "./components/Charts/Charts";
// import Coupon from "./components/Coupon/Coupon";
import MiniDrawer from "./components/layouts/MiniDrawer";
import Login from "./components/Login/Login";
import setAuthToken from "./common/setAuthToken";

function App() {
  const auth = localStorage.accessToken

  if (auth) {
    setAuthToken(auth);
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />}></Route>
        { auth ? (
          <>
            {/* <Route path="/orders" element={<Orders />}></Route>
            <Route path="/carts" element={<Carts />}></Route>
            <Route path="/notifications" element={<Notify />}></Route>
            <Route path="/status" element={<Status />}></Route>
            <Route path="/traffic" element={<Traffic />}></Route>
            <Route path="/charts" element={<Charts />}></Route>
            <Route path="/coupon" element={<Coupon />}></Route> */}
            <Route path="/dash" element={<MiniDrawer />}></Route>
            <Route path="/" element={<Login />}></Route>
          </>
        ) : (
            
        <Route path="*" element={<Navigate to="/" />} />
        )}
      </Routes>
    </div>
  );
}

export default App;
