import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import OrderItems from "./OrderItems";
const Orders = () => {
  return (
    <>
   
      <Grid
        container
        rowSpacing={{ xs: 2, sm: 2, md: 3 }}
        columnSpacing={{ xs: 0, sm: 2, md: 3 }}
      >

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box display="flex" justifyContent="flex-start">
            
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
        <Grid item md={2} lg={2} xl={2}></Grid>
        <Grid item md={1} lg={2} xl={2}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OrderItems type={"neworder"} />
        </Grid>
        <Grid item md={1} lg={2} xl={2}></Grid>
      </Grid>
    </>
  );
};

export default Orders;
