import React from 'react'
import { NotificationBreadcrumb } from '../../components/layouts/Breadcrumb'

const NotificationConfig = () => {
  return (
    <><NotificationBreadcrumb />
    <div>NotificationConfig</div></>
  )
}

export default NotificationConfig