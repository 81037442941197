import React from "react";
import { TableCell, TableRow } from "@mui/material";

function formatDate(isoDate:any) {
    try {
        const date:any = new Date(isoDate);
        if (!isNaN(date)) {
        const formattedDate = date.toLocaleDateString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
        return formattedDate;
        } else {
        return "Invalid Date";
        }
    } catch (error) {
        return "Invalid Date";
    }
}

function Cart(props:any) {
    const cartDetails = props.details;

    if (cartDetails.cartItems.length > 0) {
        return (
        <>
            {cartDetails.cartItems.map((item:any, index:any) => (
            <TableRow key={index}>
                <TableCell>{item._id}</TableCell>
                <TableCell>{formatDate(item.date)}</TableCell>
                <TableCell>{cartDetails.phoneNumber}</TableCell>
            </TableRow>
            ))}
        </>
        );
    } else {
        return null;
    }
}

export default Cart;
