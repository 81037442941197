import React, { useState } from "react";
import * as Components from "./Components";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import http from "../../http-common";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState();
  const [password, setPassword] = useState("");
  const [conpassword, setConPassword] = useState("");
  const [signIn, toggle] = useState<any>(true);

  const handleLogin = async (event: any) => {
    event.preventDefault();
    const formData = {
      username: email,
      pass: password,
    };
    try {
      const response = await http.post("admin/login", formData);
      const result: any = response.data;
      var token: any = response.data.accessToken;
      setToken(token)
      localStorage.setItem("accessToken",token)
      console.log(result.message); 
    } catch (error: any) {
      console.error("Error login:", error.response.data.message);
      alert(error.response.data.message);
    }
  };
  if (token) {
      navigate("./dash");
  }

 


    const handleRegister = async (event: any) => {
      event.preventDefault();
      if (password !== conpassword) {
        alert("password not match");
      } else {
        const formData = {
          username: email,
          pass: password,
        };
        try {
          await http.post("admin/register", formData);
          alert("User created successfully");
          toggle(true);
        } catch (error: any) {
          console.error("Error creating user:", error.response.data.message);
        }
      }
    };

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: "100px 0px 0px 0px",
        }}
      >
        <Components.Container>
          <Components.SignUpContainer signinIn={signIn}>
            <Components.Form>
              <Components.Title>Create Account</Components.Title>
              <Components.Input
                type="text"
                placeholder="UserName"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
              <Components.Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
              <Components.Input
                type="password"
                placeholder="Confrim Password"
                value={conpassword}
                onChange={(e: any) => setConPassword(e.target.value)}
              />
              <Components.Button onClick={handleRegister}>
                Sign Up
              </Components.Button>
            </Components.Form>
          </Components.SignUpContainer>

          <Components.SignInContainer signinIn={signIn}>
            <Components.Form>
              <Components.Title>Sign in</Components.Title>
              <Components.Input
                type="text"
                placeholder="UserName"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
              <Components.Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
              {/* <Components.Anchor href="#">Forgot your password?</Components.Anchor> */}
              <Components.Button onClick={handleLogin}>
                Sign In
              </Components.Button>
            </Components.Form>
          </Components.SignInContainer>

          <Components.OverlayContainer signinIn={signIn}>
            <Components.Overlay signinIn={signIn}>
              <Components.LeftOverlayPanel signinIn={signIn}>
                <Components.Title>Welcome Back!</Components.Title>
                <Components.Paragraph>
                  To keep connected with us please login with your personal info
                </Components.Paragraph>
                <Components.GhostButton onClick={() => toggle(true)}>
                  Sign In
                </Components.GhostButton>
              </Components.LeftOverlayPanel>

              <Components.RightOverlayPanel signinIn={signIn}>
                <Components.Title>Hello, Admin!</Components.Title>
                <Components.Paragraph>
                  Enter Your Credentials and start journey with us
                </Components.Paragraph>
                {/* <Components.GhostButton onClick={() => toggle(false)}>
                  Sigin Up
                </Components.GhostButton> */}
              </Components.RightOverlayPanel>
            </Components.Overlay>
          </Components.OverlayContainer>
        </Components.Container>
      </Grid>
    </>
  );
}

export default Login;
