import React, { useContext } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { menuItems } from "../../constants/config";
import InsightsIcon from "@mui/icons-material/Insights";
import RedeemIcon from "@mui/icons-material/Redeem";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CategoryIcon from "@mui/icons-material/Category";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { MyContext } from "../../common/MyContext";

const MenuItems = () => {
  const { setState } = useContext<any>(MyContext);

  const handleClick = (index:any) => {
    setState(index);
  };


  return (
    <Grid container spacing={3}>
      {menuItems.map((text, index) => (
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={4}
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: "10px 0px 10px 0px",
          }}
          key={index}
        >
        
          <Button onClick={() => handleClick(index)}>
            {index === 0 && (
              <ManageSearchIcon sx={{ color: "#F9546B", fontSize: "50px" }} />
            )}
            {index === 1 && (
              <LocalOfferIcon sx={{ color: " #FC7651", fontSize: "50px" }} />
            )}
            {index === 2 && (
              <InventoryIcon sx={{ color: "#FFDB60", fontSize: "50px" }} />
            )}
            {index === 3 && (
              <CategoryIcon sx={{ color: "#cc6c35", fontSize: "50px" }} />
            )}
            {index === 4 && (
              <ManageAccountsIcon
                sx={{ color: " #009F93", fontSize: "50px" }}
              />
            )}
            {index === 5 && (
              <AssessmentIcon sx={{ color: "#F9546B", fontSize: "50px" }} />
            )}
            {index === 6 && (
              <InsightsIcon sx={{ color: "#FE4B87", fontSize: "50px" }} />
            )}
            {index === 7 && (
              <RedeemIcon sx={{ color: "#242C89", fontSize: "50px" }} />
            )}
          </Button>
          <Typography sx={{ fontSize: "10px" }}>{text}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default MenuItems;
