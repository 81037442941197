import { useEffect, useState } from "react";
import http from "../../http-common";
import { PieChart } from "@mui/x-charts/PieChart";
import "./YearSalesPieChart.css";
import { Grid } from "@mui/material";

function YearSalesPieChart() {
  const [orders, setOrders] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get("/orders");
        const data = response.data;
        setOrders(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  // Total order amount
  // const cakeList1 = orders.map((order: any, index: any) => order.orderPrice);
  // var totalOrderPrice = 0;
  // for (let i = 0; i < cakeList1.length; i++) {
  //   totalOrderPrice += cakeList1[i];
  // }

  // create object (date and price)
  const cakeList2 = orders.map((item: any) => {
    return {
      orderDate: item.orderDate,
      orderPrice: item.orderPrice,
    };
  });

  // filter 2022 orders
  const lastYearData = cakeList2.filter(
    (item: any) => new Date(item.orderDate).getFullYear() === 2022
  );

  // filter 2023 orders
  const currentYearData = cakeList2.filter(
    (item: any) => new Date(item.orderDate).getFullYear() === 2023
  );
  //  console.log(lastYearData, currentYearData);

  // 2022 year total order amount
  var lastYearAmount = [];
  for (let i = 0; i < lastYearData.length; i++) {
    lastYearAmount.push(lastYearData[i].orderPrice);
  }
  var lastYearTotalAmount = 0;
  for (let i = 0; i < lastYearAmount.length; i++) {
    lastYearTotalAmount += lastYearAmount[i];
  }
  //  console.log("2022 total Rs.", lastYearTotalAmount);

  //  2023 year total order amount
  var currentYearAmount = [];
  for (let i = 0; i < currentYearData.length; i++) {
    currentYearAmount.push(currentYearData[i].orderPrice);
  }
  var currentYearTotalAmount = 0;
  for (let i = 0; i < currentYearAmount.length; i++) {
    currentYearTotalAmount += currentYearAmount[i];
  }
  //  console.log("2023 total Rs.", currentYearTotalAmount);

  function BasicPie() {
    return (
      <PieChart
        colors={["#eaac8b", "#bc5090"]}
        series={[
          {
            data: [
              {
                id: 0,
                value: lastYearTotalAmount,
                label: "2022",
                
              
              },
              {
                id: 1,
                value: currentYearTotalAmount,
                label: "2023",
              },
            ],
            innerRadius: 30,
            outerRadius: 100,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: 0,
            endAngle: 360,
            cx: 150,
            cy: 150,
          },
        ]}
        width={400}
        height={400}
        
        
      />
    );
  }

  return (
    <>
    
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px",
          marginBottom:"60px",
          height: "300px",
          width: "300px",
          overflow: "hidden",
        }}
      >
         
        <h3>Yearly Sales</h3>
        <BasicPie />
      </div>
    
    </>
    
  );
}

export default YearSalesPieChart;
